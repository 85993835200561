import Storage from '@/library/storage';
import Rooms from '@/pages/Rooms.vue';
import LoginPage from '@/pages/LoginPage.vue';
import Account from '@/pages/Account.vue';
import NotFound from "@/pages/NotFound";
import RoomPage from '@/pages/Room.vue';
import SignUpPage from '@/pages/SIgnUp.vue';
import AdminRoomsList from '@/pages/Admin/Rooms/List.vue';
import AdminUsersList from '@/pages/Admin/Users/List.vue';
import {createRouter, createWebHistory} from 'vue-router';

const routes = [
  {path: '/', component: Rooms, meta: {isAuth: true, title: ''}},
  {path: '/login', component: LoginPage, meta: {isAuth: false, title: 'Login'}},
  {path: '/account', component: Account, meta: {isAuth: true, title: 'Account'}},
  {path: '/signup', component: SignUpPage, meta: {isAuth: false, title: 'Signup'}},
  {path: '/admin/rooms', component: AdminRoomsList, meta: {isAuth: true, isAdmin:true, title: 'Admin Rooms List'}},
  {path: '/admin/users', component: AdminUsersList, meta: {isAuth: true, isAdmin:true, title: 'Admin Rooms List'}},
  {path: '/room/:id', component: RoomPage, meta: {isAuth: true, title: 'Room'}},
  {path: '/:notFound', component: NotFound, meta: {isAuth: true, title: '404'}},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const {isAuth, title, isAdmin} = to?.meta;
  document.title = `${ process.env["VUE_APP_NAME"] } ${ title ? '| ' + title : '' }`;
  const user = Storage.get('USER_SESSION');

  console.log(user?.user?.role)
  if (!!isAdmin && user?.user?.role != 1) {
    return next({path: "/", params: {nextUrl: to.fullPath}});
  }

  if (!!isAuth && !user) {
    Storage.set('NEXT_ROUTE', to.fullPath);
    return next({path: "/login", params: {nextUrl: to.fullPath}});
  }

  if (!isAuth && !!user) {
    return next({path: "/", params: {nextUrl: to.fullPath}});
  }

  return next();
});

export default router;
