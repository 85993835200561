<script>
import ActionLoader from '@/components/ActionLoader';
import Cards from "@/library/cards";
import FBApp from "@/library/firebase";
import AppStore from '@/store/app';
import RoomStore from '@/store/room';
const {logEvents} = FBApp;
export default {
  name: "RoomActions",

  components: {
    ActionLoader,
  },

  props:{
    isOwner: Boolean,
  },

  data: () => ({
    estimated: 100,
    cards: [...Cards],
    loading: null,
  }),

  computed: {
    hasStories() {
      return !!RoomStore?.stories?.length;
    },

    showStartAction() {
      return !!this.hasStories && !RoomStore?.isStarted;
    },

    showNextAction() {
      return !!this.hasStories && !!RoomStore?.room?.started_at && !RoomStore?.activeStory;
    },

    showOtherAction() {
      return !!this.hasStories && !!RoomStore?.activeStory && !this.showCompletedActions;
    },

    showCompletedActions() {
      return !!RoomStore?.activeStory?.completed_at;
    },
    roomStatus() {
      if(!this.hasStories){
        return "Waiting Moderator to Adding stories"
      }

      if(!!this.showStartAction){
        return "Waiting Moderator to start story"
      }

      if(!!this.showNextAction){
        return "Waiting Moderator to Select story"
      }

      if(!!this.showOtherAction){
        return "Waiting Players to vote"
      }

      if(!!this.showCompletedActions){
        return "Waiting Moderator to close Story"
      }

      return ""
    }
  },

  methods: {
    selectStory() {
      RoomStore.selectStory(RoomStore?.stories[0]?.id);
      logEvents('Story Selected');
    },
    async startingRoom() {
      this.loading = 'starting';
      AppStore.loading = false
      await RoomStore.startingRoom();
      AppStore.loading = false;
      logEvents('Story Started');
    },
    openStoryForm() {
      RoomStore.toggleStoryForm(true);
      logEvents('Open Story Form Details');
    },
    async resetTimer() {
      this.loading = 'resetting';
      await RoomStore.resetTimer();
      this.loading = null;
      logEvents('Reset Story Timer');
    },
    async setStoryCompleted() {
      this.loading = 'completing';
      await RoomStore.setStoryCompleted();
      this.loading = null;
      logEvents('Complete Story');
    },

    async setStorySkipped() {
      this.loading = 'skipping';
      await RoomStore.setStorySkipped();
      this.loading = null;
      logEvents('Skipping Story');
    },

    async setStoryClosed() {
      this.loading = 'closing';
      await RoomStore.setStoryClosed(this.estimated);
      this.estimated = 100;
      this.loading = null;
      logEvents('Close Story & Next');
    },

    async clearVotes() {
      this.loading = 'closing';
      await RoomStore.clearVotes()
      this.loading = null;
      logEvents('Clear Story Votes');
    }
  }
}
</script>

<template>
  <div class="flex flex-col border rounded bg-white mx-4 max-w-42 flex-base max-sm:w-full max-sm:mx-auto" :class="`${isOwner?'mb-5':'mb-0 shadow border-gray-300 border-b-0'}`">
    <div class="flex w-full bg-blue-500 text-white p-3 rounded-t" v-if="roomStatus">
      <span class="text-xs">{{ roomStatus }}</span>
    </div>

    <div class="flex w-full flex-col p-4 " v-if="isOwner">
      <div class="flex w-full mb-2" v-if="!hasStories">
        <Button title="Add Stories" :variant="'primary'" @click="openStoryForm" />
      </div>

      <div class="flex w-full mb-2" v-if="showStartAction">
        <Button title="Start" :variant="'primary'" @click="startingRoom" :loading="loading === 'starting'" />
      </div>

      <div class="flex w-full mb-2" v-if="showNextAction">
        <Button title="Next Story" :variant="'outline'" @click="selectStory" />
      </div>

      <div class="grid grid-cols-2 grid-rows-2 gap-4 justify-center items-center" v-if="showOtherAction">
        <Button title="Reset Timer" :variant="'outline'" @click="resetTimer" :loading="loading ==='resetting'" />
        <Button title="Flip Cards" :variant="'outline'" @click="setStoryCompleted" :loading="loading ==='completing'" />
        <Button title="Clear Votes" :variant="'outline'" @click="clearVotes" :loading="loading ==='clearing'" />
        <Button title="Skip Story" :variant="'outline'" @click="setStorySkipped" :loading="loading ==='skipping'" />
      </div>

      <div class="flex flex-col gap-4 justify-center items-center" v-if="showCompletedActions">
        <div class="flex w-full items-center font-semibold text-md">
          <label class="whitespace-nowrap me-2">Estimation Is :&nbsp;</label>
          <select
              v-model="estimated"
              class="border border-blue-500 px-4 py-1 rounded-md w-full ">
            <option :value="card.id" v-for="(card, index) in cards" :key="`card-${index}`">
              {{ card.value }}
            </option>
          </select>
        </div>
        <Button title="Close and Next" :variant="'outline'" @click="setStoryClosed" :loading="loading ==='closing'" />
      </div>
    </div>
  </div>
</template>
