<!-- src/pages/LandingPage.vue -->
<template>
  <div class="min-h-[800px] flex flex-col items-center justify-start mt-5">
    <div v-if="!isLoading && rooms.length" class="w-full max-w-5xl bg-white p-6 rounded shadow-md">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Recent Rooms</h2>
        <button @click="showForm=true" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Create Room
        </button>
      </div>

      <table class="min-w-full bg-white">
        <thead>
        <tr>
          <th class="py-2 max-sm:text-start">Title</th>
          <th class="py-2 max-sm:hidden">Stories Count</th>
          <th class="py-2 max-sm:hidden">Moderator</th>
          <th class="py-2 max-sm:hidden">Total Points</th>
          <th class="py-2 max-sm:hidden">Last Update</th>
          <th class="py-2"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="room in rooms" :key="room.id">
          <td class="border px-4 py-2">
            <router-link :to="getUrl(room)" class="text-blue-500">{{ room.title }}</router-link>
          </td>
          <td class="border px-4 py-2 text-center max-sm:hidden">{{ room?.stories?.length || 0 }}</td>
          <td class="border px-4 py-2 text-center max-sm:hidden">{{ room.user_id === userId ? 'Yes' : 'No' }}</td>
          <td class="border px-4 py-2 text-center max-sm:hidden">
            {{ room?.stories.filter(i => i.estimated < 100).flatMap(i => i.estimated).reduce((a, b) => a + b, 0) }}
          </td>
          <td class="border px-4 py-2 text-center max-sm:hidden">{{ room.updated_at }}</td>
          <td class="border px-4 py-2">
            <div class="flex w-full justify-center">
              <button
                  v-if="room.user_id === userId"
                  @click="deleteRoom(room.id)" class="text-red-500 hover:text-red-700 cursor-pointer">
                <TrashIcon class="w-6"/>
              </button>
              <div v-else class="text-sm text-blue-700">Joined</div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <TableFooter :meta="meta" @paginate="paginate" />
    </div>
    <div v-else-if="!isLoading" class="flex flex-col justify-center items-center text-center mt-12">
      <div class="flex w-full max-h-96 justify-center items-center">
        <EmptyIcon class="w-[600px] max-sm:w-[80vw]"/>
      </div>
      <h2 class="text-2xl font-bold mb-4 text-blue-900">No Rooms Yet!</h2>
      <p class="text-md text-blue-900 font-semibold p-2 w-[454px] max-sm:w-[250px] mb-4">No rooms found. Create new rooms and add stories manually or via Excel. There's no limit to the number of rooms you can set up!</p>
      <button @click="showForm = true" class="border font-semibold  border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 group rounded-md max-w-72 w-full p-2 disabled:opacity-75 flex justify-center items-center">
        Create Room
      </button>
    </div>

    <template v-if="showForm">
      <RoomForm @success="handleOnSuccess" @close="showForm = false"/>
    </template>
  </div>
</template>

<script>
import EmptyIcon from "@/assets/empty";
import TrashIcon from "@/assets/trash";
import RoomForm from "@/components/RoomForm";
import TableFooter from "@/components/TableFooter";
import Base64 from "@/library/base64";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import AppStore from "@/store/app";
import {toast} from "vue3-toastify";
const {logEvents} = FBApp;

export default {
  name: "Rooms",
  components: {
    RoomForm,
    TrashIcon,
    EmptyIcon,
    TableFooter
  },
  data() {
    return {
      search: '',
      showForm: false,
      rooms: [],
      meta: null,
      page: 1
    };
  },
  mounted() {
    this.fetchRooms();
  },

  computed: {
    isLoading() {
      return !!AppStore.isLoading;
    },
    userId() {
      return AppStore.userId;
    },
  },

  methods: {
    getUrl(room) {
      return `/room/${ Base64.encrypt(`${ room.id }?v=join`) }`;
    },
    deleteRoom(id) {
      logEvents(`Delete Room Id is ${id}`);
      const isConfirm = confirm('Are you sure to delete Room?');
      if (!isConfirm) {
        return;
      }
      axios.delete(`/room/${ id }`)
          .then(() => {
            this.fetchRooms()
          }).catch((error) => {
        toast.error(error?.message || 'Something went wrong!')
      })
    },

    handleOnSuccess(room) {
      this.showForm = false;
      const link = `/room/${ Base64.encrypt(`${ room.id }?v=join`) }`;
      this.$router.push(link);
      this.fetchRooms();
    },

    fetchRooms() {
      AppStore.loading = true;
      axios.get('/room/fetch',{
        params: {
          page: this.page,
          perPage: 50
        }
      }).then(({result}) => {
        this.rooms = result.rooms;
        this.meta = result.meta;
      }).finally(() => {
        AppStore.loading = false;
      })
    },

    paginate(page) {
      this.page = page;
      this.fetchRooms();
    }
  },
};
</script>
