<script>
import Cards from '@/library/cards';
import RoomStore from '@/store/room';


export default {
  name: "RoomChart",
  methods: {
    getCardLabel(id) {
      return Cards.find(i => i.id === id)?.value || ''
    }
  },
  computed: {
    players() {
      return RoomStore.players
    },
    playerVotesLength() {
      return RoomStore.scores?.length
    },
    votes() {
      const scores = RoomStore.scores;
      const list = [];
      scores.map(i => {
        if (!list[`${ i.score }`]) {
          list[`${ i.score }`] = {
            value: 1,
            score: i.score
          }
        } else {
          list[`${ i.score }`].value = 1 + list[`${ i.score }`].value;
        }

      })

      return list;
    },
    options() {
      // set default label
      let label = [`${ this.getCardLabel(100) } - Votes (${ this.players?.length })`];

      // set votes labels
      if (this.votes?.length) {
        label = this.votes?.flatMap(i => `${ this.getCardLabel(i.score) } -  Votes (${ i.value })`)
      }

      return {
        plotOptions: {
          pie: {
            customScale: 1,
            dataLabels: {
              offset: -1,
              minAngleToShowLabel: 10
            },
            donut: {
              size: '70%',
              labels: {
                show: true,
                total: {
                  label: `${ this.playerVotesLength } player voted`,
                  showAlways: true,
                  show: true,
                  formatter: () => {
                    // let maxValue = 0;
                    // let maxLabel = '';
                    // const votes = this.votes.filter(i => !!i)
                    // const values = Object.values(votes);
                    //
                    // votes.map((el) => {
                    //   const valueFromObject = el.value;
                    //   maxValue = Math.max(maxValue, valueFromObject);
                    //   maxLabel = maxValue ? el.score : maxLabel;
                    // });
                    //
                    // return `Avg (${ this.getCardLabel(maxLabel) })`
                    return ``
                  }
                }
              }
            }
          }
        },
        labels: label,
        colors: [
          '#f8e61b', '#3399ff', '#6699ff', '#cc33ff', '#ffcc99', '#ff6699', '#66ff66','#99cc00',
          '#cc66ff', '#ff99cc', '#ff9966', '#66cc66', '#66cccc', '#ff5050', '#ffcc33', '#66ccff',
          '#ff99ff', '#ff9933', '#99cc99', '#66ffcc', '#cc6699', '#ff66cc', '#ffcc66', '#99ff33',
          '#ffb402', '#f30e83', '#ba1ad5', '#00aaff', '#00cc99', '#ff6666', '#ffcc00', '#33cc33',
          '#ccff66',
        ],

      }
    },
    series() {
      // set default series
      let series = [100];

      // set votes series 0 to players.length
      if (this.votes?.length) {
        series = this.votes?.flatMap(i => i.value)
      }

      return series
    },

    chartWidth() {
      return window.innerWidth < 450 ? 350 : 450
    },
  }
}
</script>

<template>
  <div
      class="flex flex-col justify-center items-center w-full bg-white border border-gray-200 shadow rounded py-8 mb-4">
    <ApexChart :width="chartWidth" type="donut" :options="options" :series="series"/>
  </div>
</template>
