<!-- src/pages/LandingPage.vue -->
<template>
  <div class="min-h-[800px] flex flex-col items-center justify-start mt-5">
    <div class="w-full max-w-5xl py-6">
      <span class="font-bold text-xl">Room Admin List</span>
    </div>
    <div v-if="!isLoading && rooms.length" class="w-full max-w-5xl bg-white p-6 rounded shadow-md">

      <table class="min-w-full bg-white">
        <thead>
        <tr>
          <th class="py-2 max-sm:text-start">Title</th>
          <th class="py-2 max-sm:hidden">Stories Count</th>
          <th class="py-2 max-sm:hidden">Moderator</th>
          <th class="py-2 max-sm:hidden">Total Points</th>
          <th class="py-2 max-sm:hidden">Players</th>
          <th class="py-2 max-sm:hidden">Last Update</th>
          <th class="py-2"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="room in rooms" :key="room.id">
          <td class="border px-4 py-2">
            <router-link :to="getUrl(room)" class="text-blue-500">{{ room.title }}</router-link>
          </td>
          <td class="border px-4 py-2 text-center max-sm:hidden">{{ room?.stories?.length || 0 }}</td>
          <td class="border px-4 py-2 text-center max-sm:hidden">
            <span class="flex text-sm">{{ room?.admin?.username}}</span>
            <span class="flex text-xs text-gray-400">{{ room?.admin?.email }}</span>
          </td>
          <td class="border px-4 py-2 text-center max-sm:hidden">
            {{ room?.stories.filter(i => i.estimated < 100).flatMap(i => i.estimated).reduce((a, b) => a + b, 0) }}
          </td>
          <td class="border px-4 py-2 text-center max-sm:hidden">{{ room.players?.length }}</td>
          <td class="border px-4 py-2 text-center max-sm:hidden">{{ room.updated_at }}</td>
          <td class="border px-4 py-2">
            <div class="flex w-full justify-center">
              <button @click="deleteRoom(room.id)" class="text-red-500 hover:text-red-700 cursor-pointer">
                <TrashIcon class="w-6"/>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <TableFooter :meta="meta" @paginate="paginate" />
    </div>
  </div>
</template>

<script>
import TrashIcon from "@/assets/trash";
import Base64 from "@/library/base64";
import FBApp from "@/library/firebase";
import TableFooter from "@/components/TableFooter";
import axios from "@/library/request";
import AppStore from "@/store/app";
import {toast} from "vue3-toastify";
const {logEvents} = FBApp;

export default {
  name: "RoomsList",
  components: {
    TrashIcon,
    TableFooter
  },
  data() {
    return {
      search: '',
      showForm: false,
      rooms: [],
      meta: null,
      page: 1
    };
  },
  mounted() {
    this.fetchAllRooms();
  },

  computed: {
    isLoading() {
      return !!AppStore.isLoading;
    },
    userId() {
      return AppStore.userId;
    },
  },

  methods: {
    getUrl(room) {
      return `/room/${ Base64.encrypt(`${ room.id }?v=view`) }`;
    },
    deleteRoom(id) {
      logEvents(`Delete Room Id is ${id}`);
      const isConfirm = confirm('Are you sure to delete Room?');
      if (!isConfirm) {
        return;
      }
      axios.delete(`/room/${ id }`)
          .then(() => {
            this.fetchAllRooms()
          }).catch((error) => {
        toast.error(error?.message || 'Something went wrong!')
      })
    },

    fetchAllRooms() {
      AppStore.loading = true;
      axios.get('admin/room/fetch/all', {
        params: {
          page:this.page,
          perPage: 50
        }
      }).then((result) => {
        this.rooms = result.rooms;
        this.meta = result.meta;
      }).finally(() => {
        AppStore.loading = false;
      })
    },
    paginate(page){
      this.page = page;
      this.fetchAllRooms();
    }
  },
};
</script>
