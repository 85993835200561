<script>
export default {
  name: "TableFooter",
  emits:['paginate'],
  props: {
    meta: Object
  },
  computed: {
    disableNext() {
      return !this.next || this.next === this.current
    },
    disablePrev() {
      return !this.prev
    },
    prev(){
      return this.meta?.prev_page;
    },
    next(){
      return this.meta?.next_page;
    },
    current(){
      return this.meta?.current_page;
    }
  }
}
</script>

<template>
  <div class="w-full flex max-w-5xl pt-6 justify-between">
    <div class="flex cursor-pointer"
         @click="$emit('paginate', prev)"
         :class="disablePrev?'text-gray-400 pointer-events-none':'text-blue-600'">
      Previous
    </div>
    <div
        @click="$emit('paginate', next)"
        class="flex cursor-pointer"
        :class="disableNext?'text-gray-400 pointer-events-none':'text-blue-600'"
    >
      Next
    </div>
  </div>
</template>
