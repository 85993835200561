<template>
  <div class="relative" @focusout="closeMenu" @blurout="closeMenu">
    <slot name="menuButton" :toggleMenu="toggleMenu">Link</slot>
    <div v-if="isOpen" class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20 menu-class">
      <div class="py-2 menu-body relative">
        <div class="justify-end items-center hidden max-sm:flex">
          <button @click="toggleMenu" class="absolute top-2 end-5 px-2 text-gray-600 hover:text-gray-900 text-2xl">&times;</button>
        </div>
        <slot name="menuBody" :toggleMenu="toggleMenu">
          list
        </slot>
      </div>

    </div>
  </div>
</template>

<script>
import MenuIcon from "@/assets/menu";
import axios from "@/library/request";
import Storage from "@/library/storage";
import AppStore from "@/store/app";

export default {
  components: {
    MenuIcon
  },
  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    username() {
      const user = AppStore.session?.user;
      return user?.username?.split(' ')?.[0] || '';
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },

    logout(event) {
      event.preventDefault();
      this.closeMenu();
      axios.post('/auth/logout')
          .finally(() => {
            AppStore.session = null;
            Storage.remove('USER_SESSION');
            this.$router.replace({path: '/login'});
          })
    },

    closeMenu(event) {
      setTimeout(() => {
        if (!this?.isOpen) {
          return;
        }
        this.isOpen = false
      }, 250)
    }
  },
};
</script>
