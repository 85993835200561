<!-- src/pages/LandingPage.vue -->
<template>
  <div class="min-h-screen flex items-center justify-center">
    <div class="text-center">
      <h2 class="text-2xl font-bold mb-4">{{ AppName }}</h2>
      <p class="text-2xl font-bold mb-4">
        404 Page Not Found
      </p>
      <button @click="joinRoom" class="bg-blue-500 text-white px-4 py-2 rounded">
        Create New
      </button>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "LandingPage",
  components: {
    Header,
  },
  data() {
    return {
      roomCode: "",
    };
  },
  computed: {
    AppName() {
      return process?.env?.VUE_APP_NAME
    }
  },
  methods: {
    mounted() {
      // this.$router.push(`/room/222`);
    },
    joinRoom() {
      // this.$router.push(`/room/${ this.roomCode }`);
    },
  },
};
</script>
