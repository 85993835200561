<script>
import RoomPlayer from '@/components/RoomPlayer';
import FBApp from "@/library/firebase";
import Utils from "@/library/utils";
import RoomStore from '@/store/room';
import {toast} from "vue3-toastify";
const {logEvents} = FBApp;

export default {
  name: "RoomPlayers",

  components: {
    RoomPlayer
  },

  props:{
    isOwner: Boolean,
  },

  data: () => ({
    hasShare: false,
    userScore: []
  }),

  watch: {
    scores: {
      deep: true,
      immediate: true,
      handler(scores) {
        scores?.forEach(score => {
          this.userScore[score.player_id] = score
        })
      }
    }
  },

  computed: {
    players() {
      return RoomStore.players || [];
    },

    scores() {
      return RoomStore.scores;
    },

    timer() {
      return RoomStore.timer?.timeFormatted
    },

    storyStarted() {
      return RoomStore.activeStory?.started_at
    },

    storyCompleted() {
      return RoomStore.activeStory?.completed_at
    },

    completedAt() {
      const startTime = this.storyStarted;
      const endTime = this.storyCompleted;

      if (!startTime || !endTime) {
        return '-';
      }

      const elapsedMilliseconds = new Date(startTime) - new Date(endTime);
      return Utils.getFormattedTime(Math.floor(Math.abs(elapsedMilliseconds) / 1000));
    },

    pageUrl() {
      return window.location.href
    },
  },
  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.pageUrl);
      toast.success('URL copied to clipboard');
      logEvents('Copy Room Link')
    }
  },
}
</script>

<template>
  <div
      class="flex flex-col border border-gray-300 py-4 bg-white mx-4 max-w-42 flex-base max-sm:w-full max-sm:mx-auto"
      :class="`${isOwner?'rounded':'rounded-b border-t-0'}`"
  >
    <div class="flex justify-between items-center px-4 mb-2">
      <span class="text-xl font-smiebold text-gray-700">Players : </span>
      <span class="font-semibold text-blue-500 text-xl">
        <template v-if="storyStarted && !storyCompleted">{{ timer }}</template>
        <template v-if="storyCompleted">{{ completedAt }}</template>
      </span>
    </div>
    <ul class="flex flex-col" :key="`player-${userScore?.length}`">
      <li v-for="player in players" class="p-4 border-b border-b-gray-300">
        <room-player :player="player" :key="player.id" :score="userScore?.[player.id]"/>
      </li>
    </ul>

    <div class="flex flex-col w-full">
      <button class="flex w-full justify-between items-center p-4 pb-0"
              @click="hasShare = !hasShare"
      >
        <span class="text-md font-semibold text-gray-500">
          Invite a teammate
        </span>
        <svg data-v-b72c1b0c="" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 21 21">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                d="m14.5 8.5-4 4-4-4">
          </path>
        </svg>
      </button>
      <div class="flex w-full justify-between items-center p-4 max-sm:flex-col" v-if="hasShare">
        <input id="room-url" type="url" :value="pageUrl"
               class="border border-gray-200 rounded p-2 flex-grow me-1 max-sm:w-full">
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button id="share-url"
                @click="copyUrl"
                class="border border-blue-500 text-blue-500 px-4 py-2 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 rounded-md max-sm:w-full max-sm:py-2">
          Copy URL
        </button>
      </div>
    </div>
  </div>
</template>
