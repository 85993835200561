<script>
import CoffeeIcon from "@/assets/Coffee";
import TickIcon from '@/assets/tick';
import TrashIcon from '@/assets/trash';
import UserIcon from '@/assets/user';
import Cards from "@/library/cards";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import Utils from '@/library/utils';
import AppStore from '@/store/app';
import RoomStore from '@/store/room';

const {logEvents} = FBApp;
export default {
  name: "RoomPlayer",

  components: {
    UserIcon,
    TickIcon,
    TrashIcon,
    CoffeeIcon
  },

  props: {
    player: Object,
  },

  watch: {
    scores: {
      deep: true,
      immediate: true,
      handler(scores) {
        this.estimation = scores?.find(score => score.player_id === this.player?.user_id);
      }
    }
  },

  data: () => ({
    cards: [...Cards],
    openSettingMenu: null,
    estimation: null,
    moderator: require('@/assets/moderator.png'),
  }),

  computed: {
    playerName() {
      return this.player.user?.username?.split(' ')?.[0] || this.player.user?.username;
    },
    playerImage() {
      return this.player.user?.image;
    },
    isOwner() {
      return RoomStore.room?.user_id === this.player?.user_id;
    },

    hasDelete() {
      return RoomStore.room?.user_id === AppStore.session?.user?.id && !this.isOwner
    },

    scores() {
      return RoomStore.scores;
    },

    activeStory() {
      return RoomStore.activeStory;
    },

    storyStartedAt() {
      return RoomStore?.activeStory?.started_at;
    },

    hasVoted() {
      return !!this?.estimation;
    },

    storyCompleted() {
      return RoomStore.activeStory?.completed_at;
    },

    scoreValue() {
      if (!this.estimation) {
        return 0;
      }
      const score = this.cards?.find(i => i.id === this.estimation.score);
      return score?.value;
    },

    estimatedAt() {
      const elapsedMilliseconds = new Date(this.storyStartedAt) - new Date(this.estimation?.estimated_at);
      if (!elapsedMilliseconds || isNaN(elapsedMilliseconds)) {
        return ''
      }
      return Utils.getFormattedTime(Math.floor(Math.abs(elapsedMilliseconds) / 1000));
    },
  },

  methods: {
    deletePlayer() {
      const isConfirmed = confirm('Are you sure to delete this player?');

      if (!isConfirmed) {
        return
      }

      const roomId = RoomStore.room?.id;
      const playerId = this.player?.id;
      axios.delete(`/room/${ roomId }/player/${ playerId }`);
      logEvents('Remove Player');
    }
  }
}
</script>

<template>
  <div class="flex w-full gap-2 items-center relative">

    <div :class="`${isOwner?'border-blue-500':''}`"
         class="flex w-10 h-10 relative border-2 justify-center items-center text-white bg-gray-200 rounded-full overflow-hidden cursor-pointer group">
      <template v-if="!playerImage">
        <UserIcon class="w-6 text-black z-10"/>
      </template>
      <template v-else>
        <img :src="playerImage" :alt="player.user?.username" class="object-cover w-full h-full z-10"
             referrerpolicy="no-referrer"/>
      </template>
      <div v-if="hasDelete"
           role="button"
           @click="deletePlayer"
           class="opacity-0 absolute top-0 left-0 right-0 bg-white bg-opacity-80 w-full h-full flex justify-center cursor-pointer items-center group-hover:opacity-100 z-10">
        <TrashIcon class="w-5 text-red-600"/>
      </div>

    </div>
    <div class="flex flex-col flex-grow">
      <div class="flex w-full">
        <span class="flex text-gray-600 text-xl">
          {{ playerName }}
        </span>&nbsp;
        <span class="text-blue-500" v-if="hasVoted"><TickIcon/></span>
      </div>
      <div class="flex w-full">
        <span class="text-gray-500" v-if="hasVoted">{{ estimatedAt }}</span>
      </div>
    </div>
    <div class="ml-auto">
      <div class="font-semibold text-xl text-blue-300 pe-1 flex items-center" v-if="storyCompleted">
        <span v-if="scoreValue !== 'Coffee'">{{ scoreValue || '' }}</span>
        <CoffeeIcon v-else class="w-10" />
      </div>
    </div>
  </div>
</template>
