import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut} from 'firebase/auth';
import FBApp from '@/library/firebase';
const {Auth} = FBApp;

import {ref} from 'vue'
export const useAuth = () => {
  const localUser = ref(null);

  let unwatchAuthState = () => {
    //
  };

  const loginInWithGoogle = async () => {
    if(!!localUser.value){
      await logoutFromGoogle();
    }
    const provider = new GoogleAuthProvider();
    return new Promise(async (resolve, reject) => {
      try {
        const {user} = await signInWithPopup(Auth, provider);
        localUser.value = {...user};
        resolve({
          email: user?.email,
          password: user?.uid,
          username: user?.displayName,
          image: user?.photoURL,
        });
      } catch (error) {
        reject(error);
      }
    })
  }

  const logoutFromGoogle = async () => {
    try{
      await signOut(Auth);
      await unwatchAuthState();
      localUser.value = null;
    } catch (e){
      console.log('Error', e);
    }
  }

  const watchAuthState = () => {
    unwatchAuthState()

    unwatchAuthState = onAuthStateChanged(Auth, user => {
      if (user) {
        localUser.value = user
      } else {
        localUser.value = null
      }
    })
  }

  return {
    loginInWithGoogle,
    logoutFromGoogle,
    watchAuthState,
    unwatchAuthState
  }
}
