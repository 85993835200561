import Storage from "@/library/storage";
import {reactive} from 'vue';

const App = reactive({
  isLoading: false,
  user: null,

  set loading(isLoading) {
    this.isLoading = isLoading
  },
  set session(user) {
    this.user = user;

    if (!user) {
      Storage.remove('USER_SESSION');
      return;
    }

    Storage.set('USER_SESSION', user);
  },

  get session() {
    return this.user || Storage.get('USER_SESSION');
  },

  get userId() {
    return this.session?.user?.id || null;
  },

  get isAdmin() {
    return this.session?.user?.role === 'SuperAdmin';
  }
});

export default App;
