<script>
import ActionLoader from '@/components/ActionLoader';

export default {
  name: "AppButton",
  emits: ['click'],
  components: {
    ActionLoader
  },
  data: () => ({
    buttonVariants: {
      primary: 'text-white bg-blue-500',
      outline: 'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5'
    },
    loaderColor: {
      primary: '#fff',
      outline: '#66b1fa'
    },
    loaderFillColor:{
      primary: '#5f708b',
      outline: '#ccc'
    }
  }),
  props: {
    variant: {
      default: 'primary',// primary, outline
    },
    loading: {
      default: false
    },
    buttonClass: {
      default: ''
    },
    title: {
      default: ''
    }

  }
}
</script>

<template>
  <button
      @click="$emit('click')"
      v-bind="$attrs"
      :class="`${buttonClass} ${buttonVariants[variant]}`"
      :disabled="!!loading"
      class="group rounded w-full p-2 disabled:opacity-75 flex justify-center items-center"
  >
    <span class="group-disabled:opacity-75">{{ title }}</span>
    <ActionLoader v-if="loading" :color="loaderColor[variant]" :fill-color="loaderFillColor[variant]"/>
  </button>
</template>
