<script>
import Cards from "@/library/cards";
import axios from "@/library/request";
import CoffeeIcon from "@/assets/Coffee";
import Storage from "@/library/storage";
import RoomStore from "@/store/room";
import {toast} from "vue3-toastify";

export default {
  name: "CardPokers",
  components: {
    CoffeeIcon
  },
  data: () => ({
    cards: [...Cards],
    selectedCards: [],
    showConfirmModal: false
  }),

  computed: {
    userId() {
      return Storage.get('USER_SESSION')?.user?.id
    },

    activeStory() {
      return RoomStore?.activeStory
    },
  },

  watch:{
    activeStory:{
      immediate: true,
      handler(newStory, oldStory) {
        if (newStory?.id !== oldStory?.id) {
          this.selectedCards = [];
        }
      }
    }
  },

  methods: {
    handleCardClick(card) {
      if (!RoomStore?.activeStory) {
        return toast.info('You must be in a story to vote');
      }

      if (!!RoomStore?.activeStory?.closed_at || !!RoomStore?.activeStory?.complete_at) {
        return toast.info('Store closed or complete!');
      }

      axios.post('story/estimate', {
        player_id: this.userId,
        story_id: RoomStore.activeStory?.id,
        score: card.id,
      }).then((data) => {
        toast.success(data?.message || 'Voted');
        this.updateOrAddEstimate(data.estimate);
      }).catch((error) => {
        toast.error(error.message || 'Something went wrong');
      })
      this.selectedCards = card.id
    },

    updateOrAddEstimate(newEstimate) {
      // Check if the new estimate already exists
      const existingEstimateIndex = RoomStore.activeStory.estimates.findIndex(
          est => est.player_id === newEstimate.player_id
      );

      if (existingEstimateIndex !== 100) {
        // Update existing estimate
        RoomStore.activeStory.estimates[existingEstimateIndex] = {
          ...newEstimate
        }
        return;
      }

      RoomStore.activeStory.estimates.push(newEstimate);
    }
  },
}
</script>

<template>
  <div class="flex flex-grow flex-wrap gap-6 w-full p-4 items-center justify-center max-sm:gap-2 max-sm:py-4 max-sm:px-0 max-sm:justify-evenly">
    <div v-for="(card, index) in cards" :key="`card-${index}`"
         @click="handleCardClick(card)"
         :class="`${selectedCards === card.id?'bg-blue-500 text-white':'bg-white'}`"
         class="flex flex-col w-32 h-40 border-2 border-gray-200 rounded shadow overflow-hidden cursor-pointer hover:bg-blue-50 hover:text-blue-700 hover:border-blue-200 p-1 max-sm:h-32 max-sm:w-24"
    >
      <div class="flex text-2xs">{{ card.value }}</div>
      <div class="flex px-2 flex flex-col w-full h-full">
        <div class="flex flex-col w-full h-full border border-gray-300 justify-center items-center text-3xl rounded">
          <span v-if="card.id !== 101">{{ card.value }}</span>
          <CoffeeIcon v-else />
        </div>
      </div>
      <div class="flex text-2xs rotate-180">{{ card.value }}</div>
    </div>
  </div>
</template>
