<script>
import FBApp from "@/library/firebase";
import RoomStore from "@/store/room";
import UploadIcon from '@/assets/upload';
import {toast} from "vue3-toastify";
import XLSX from "xlsx"
const {logEvents} = FBApp;

export default {
  name: "ImportStories",
  emits: ['fetchStories', 'closeImport'],
  components:{
    UploadIcon
  },
  data: () => ({
    file: null,
    stories: [],
    isLoading: false
  }),

  methods: {
    onChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]).then(
          (result) => {
            result.splice(0, 1);
            this.stories = result
                .map(text => text?.[0])
                .filter(text => !!text?.trim() && !!text?.length)
                .map(text => text.substring(0, 99));
            console.log('result', result);
          },
          error => {
            console.log('error', error);
          }
      );
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          // reader.onload = event => {
          //   resolve(reader.result, event);
          // };
          // reader.readAsText(file);

          reader.onload = (e) => {
            const bStr = e.target.result;
            const wb = XLSX.read(bStr, {type: 'binary'});
            const wsName = wb.SheetNames[0];
            const ws = wb.Sheets[wsName];
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            resolve(data);
          }

          reader.readAsBinaryString(file);
        } catch (error) {
          reject(error);
        }
      });
    },

    handleOnSave() {
      this.isLoading = true;
      RoomStore.storeStories([...this.stories])
          .then(data => {
            toast.success(data?.message || 'Saved successfully');
            this.$emit('fetchStories');
            this.$emit('closeImport');
            RoomStore.toggleStoryForm(false);
            this.stories = [];
            logEvents('Import File Successfully');
          })
          .catch(error => {
            toast.error(error?.message || 'Something went wrong!')
            logEvents(`Error Import File ${error?.message}`);
          })
      .finally(()=>{
        setTimeout(()=>{
          this.isLoading = false;
        }, 1000)
      })
    }
  }
}
</script>

<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full">
      <button @click="$emit('closeImport')" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900">&times;
      </button>
      <h2 class="text-xl font-semibold mb-4">Import Stories</h2>

      <div class="flex p-2 border-t-2 border-gray-200 pt-5">
        <div
            v-if="!stories?.length"
            class="flex w-full flex-col cursor-pointer h-32 border-2 border-dashed border-blue-100 rounded p-4 bg-blue-50 items-center justify-center relative">
          <UploadIcon class="text-gray-600 mb-2" />
          <span class="text-sm text-gray-700"> Drop a file here to upload, or</span>
          <span class="text-sm underline text-gray-700">click here to browse</span>
          <input type="file" class="w-full h-full absolute opacity-0 cursor-pointer"
                 accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                 @change="onChange">
        </div>

        <div v-else class="flex w-full flex-col relative">
          <div class="flex text-lg font-bold bg-gray-100 p-2 mx-2">
            Story Name
          </div>
          <div class="overflow-y-auto h-72 p-2 rounded">
            <div class="flex text-md font-semibold hover:bg-gray-100 p-2"
                 :class="{ 'bg-blue-50': index % 2 === 0 }"
                 v-for="(story, index) in stories"
                 :key="`story-${index}`"
            >
              {{ story }}
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 grid-rows-1 gap-2 px-2 mt-4 border-t-2 border-gray-200 pt-4">
        <button type="button" @click="handleOnSave"
                :disabled="!stories?.length || isLoading"
                class="px-4 py-2 border border-blue-500 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full disabled:opacity-50">
          Save
        </button>
        <button type="button" @click="$emit('closeImport')"
                class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 group rounded-md w-full p-2 disabled:opacity-75 flex justify-center items-center">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
