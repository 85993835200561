import Utils from '@/library/utils';

export default class Timer {
    timeFormatted = null;
    constructor(startedAt, onUpdate, onComplete) {
        this.startedAt = new Date(startedAt);
        this.onUpdate = onUpdate;
        this.onComplete = onComplete;
        this.timer = null;
    }

    calculateElapsedSeconds() {
        const now = new Date();
        const elapsedMilliseconds = now - this.startedAt;
        return Math.floor(elapsedMilliseconds / 1000);
    }

    start() {
        if (this.timer) return; // Prevent multiple intervals

        this.timer = setInterval(() => {
            const elapsedSeconds = this.calculateElapsedSeconds();

            if (elapsedSeconds < 23600) {
                if (this.onUpdate) this.onUpdate(elapsedSeconds);
                this.timeFormatted = Utils.getFormattedTime(elapsedSeconds);
            } else {
                this.stop();
                if (this.onComplete) this.onComplete();
            }
        }, 1000);
    }

    stop() {
        clearInterval(this.timer);
        this.timer = null;
    }

    reset() {
        this.stop();
        this.startedAt = new Date();
        if (this.onUpdate) this.onUpdate(0);
    }
}
