<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full">
      <button @click="closePopup" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900">&times;</button>
      <h2 class="text-xl font-semibold mb-4">Story Details</h2>
      <form @submit.prevent="handleSave">
        <div class="mb-4">
          <label for="storyText" class="block text-sm font-medium text-gray-700">Story Title:</label>
          <textarea id="storyText" v-model="storyData.title" rows="2"
                    :disabled="!isOwner"
                    class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required>
          </textarea>
        </div>

        <div class="mb-4">
          <label for="storyNote" class="block text-sm font-medium text-gray-700">Story Note:</label>
          <textarea id="storyNote" v-model="storyData.note" rows="4"
                    :disabled="!isOwner"
                    class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
          >
          </textarea>
        </div>
        <div class="mb-4">
          <label for="storyLink" class="block text-sm font-medium text-gray-700">Story Link (optional):</label>
          <input type="url" id="storyLink" v-model="storyData.url"
                 :disabled="!isOwner"
                 class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
        </div>

        <div class="flex gap-2">
          <div class="mb-4 flex flex-col flex-grow w-2/3">
            <label for="storyLabel" class="block text-sm font-medium text-gray-700">Story Label:</label>
            <input type="text" id="storyLabel" v-model="storyData.label"
                   :disabled="!isOwner"
                   class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
          </div>

          <div class="mb-4 flex flex-col flex-grow w-1/3">
            <label for="labelColor" class="block text-sm font-medium text-gray-700">Story Label:</label>
            <input type="color"
                   :disabled="!isOwner"
                   id="labelColor" v-model="storyData.label_color"
                   class="mt-1 block w-full border border-gray-300 rounded sm:text-sm h-10">
          </div>
        </div>
        <div class="flex gap-4">
          <button type="button" @click="closePopup"
                  class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 group rounded w-full p-2 disabled:opacity-75 flex justify-center items-center">
            Cancel
          </button>
          <button type="button"
                  v-if="!!isOwner"
                  @click="handleSave"
                  class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full">Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import RoomStore from '@/store/room';
import {toast} from "vue3-toastify";
const {logEvents} = FBApp;
export default {
  emits: ['fetchStories', 'close'],
  props: {
    story: Object
  },
  data() {
    return {
      roomId: this.$route.params.id,
      isVisible: false,
    };
  },

  computed:{
    isOwner() {
      return RoomStore?.isIamOwner;
    },
    storyData() {
      return {...this.story};
    },
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },

    handleSave() {
      const color = !this.storyData.label_color && !!this.storyData.label?'#000':this.storyData.label_color
      axios.put(`story/${this.storyData.id}`, {
        title: this.storyData.title.substring(0, 99),
        color,
        url: this.storyData.url,
        label: this.storyData.label,
        note: this.storyData.note
      }).then(data => {
        toast.success(data?.message || 'Saved successfully');
        this.$emit('fetchStories')
        this.closePopup();
        logEvents('Success Updated Story Details');
      }).catch(error => {
        toast.error(error?.message || 'Something went wrong!');
        logEvents('Failed to Updated Story Details');
      })
    },
  }
};
</script>
