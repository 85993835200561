<template>
  <div class="min-h-[600px] h-full flex items-center justify-center ">
    <div class="relative bg-white p-8 rounded shadow-md w-full max-w-md">
      <h2 class="text-2xl font-bold mb-6 text-center">Account</h2>
      <form @submit.prevent="handleSubmit">
        <div class="mb-4 flex justify-center">
          <div class="flex group relative w-32 h-32 overflow-hidden relative group items-center justify-center">
            <div class="flex w-full h-full" v-if="image">
              <img :src="image" alt=""
                   @error="handleImageError"
                   referrerpolicy="no-referrer"
                   class="rounded-full w-full h-full object-cover border-4 border-blue-300" />
            </div>
            <div class="flex w-32 h-32 overflow-hidden" v-else>
              <img :src="UserIcon" />
            </div>
            <div class="rounded-full flex opacity-0 group-hover:opacity-90 absolute w-full h-full justify-center items-center bg-gray-100 bg-opacity-80">
              <SettingIcon class="w-24 h-24 text-blue-500 z-1"/>
            </div>
            <input
                type="file"
                class="w-32 h-32 absolute opacity-0 z-10 cursor-pointer top-0 right-0 left-0"
                @change="handleOnChangeImage"
            />
          </div>
        </div>

        <div class="mb-4">
          <input
              type="text"
              id="username"
              placeholder="Username"
              v-model="username"
              class="w-full p-2 border rounded"
              autocomplete="off"
              :class="{'border-red-500': usernameError}"
          />
          <p v-if="usernameError" class="text-red-500 text-sm mt-1">{{ usernameError }}</p>
        </div>
        <div class="mb-4">
          <input
              type="text"
              id="email"
              placeholder="Email"
              v-model="email"
              disabled
              class="w-full p-2 border rounded"
          />
        </div>

        <div class="mb-4">
          <input
              type="text"
              id="timezone"
              v-model="timezone"
              placeholder="Timezone"
              autocomplete="off"
              disabled
              class="w-full p-2 border rounded"
          />
        </div>
        <div
            v-if="user?.auth !== 'google'"
            @click="linkAccountWithGoogle"
            class="flex items-center text-sm text-blue-500 hover:underline cursor-pointer"
            :class="{'opacity-50 pointer-events-none': isLoading}"
        >
          Link your account with Google Account
        </div>
        <div class="flex w-full pb-6 pt-4">
          <div class="flex w-full border border-gray-200"></div>
        </div>
        <button
            type="submit"
            :disabled="isLoading"
            class="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:opacity-50"
        >
          Update
        </button>
      </form>
    </div>
  </div>
</template>

<script>
const {logEvents} = FBApp;
import SettingIcon from '@/assets/setting';
import {useAuth} from "@/composable/useAuth";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import Storage from "@/library/storage";
import AppStore from "@/store/app";
import {toast} from 'vue3-toastify';
import Images from "@/library/images";
const useGAuth = useAuth();
export default {
  components: {
    SettingIcon
  },
  data() {
    return {
      username: '',
      email: '',
      usernameError: '',
      timezone: '',
      userImage: null,
      image: '',
      isLoading: false,
      UserIcon: require('@/assets/profile-pic.png'),
    };
  },

  computed:{
    user() {
      return AppStore.session?.user;
    }
  },
  methods: {
    async handleImageError(event){
      try{
        const response = await fetch(this.image);
        console.log(response, this.image);
      }catch (e){
        console.log('error', e)
      }
    },
    validateUsername(username) {
      return username.length >= 3;
    },
    async handleOnChangeImage(event) {
      this.isLoading= true;
      const file = event.target.files[0];
      if (!file) {
        this.isLoading= false;
        return;
      }
      await Images.compressImage(file).then(async image => {
        await Images.convertFileToBlob(image).then(async result => {
          this.image = result
        })
      });
      this.isLoading= false;
    },
    handleSubmit() {
      this.usernameError = '';
      this.emailError = '';
      this.passwordError = '';

      if (!this.validateUsername(this.username)) {
        this.usernameError = 'Username must be at least 3 characters long';
      }

      if (!!this.usernameError) {
        toast.error('Error: Check required fields in form');
        return;
      }

      this.isLoading= true;
      // Handle signup logic here
      axios.put('/auth/account', {
        username: this.username,
        timezone: this.timezone,
        image: this.image? this.image : null,
      }).then(({user, message}) => {
        AppStore.session = {
          ...AppStore.session,
          user
        }
        Storage.set('USER_SESSION', {...AppStore.session});
        logEvents('Success Update User Data');
        toast.success(message || 'Updated Successfully');
      }).catch((error) => {
        logEvents(`Error: Update User Data ${error?.message }`);
        toast.error(error?.message || 'Something went wrong!');
      }).finally(()=>{
        setTimeout(()=>{
          this.isLoading = false;
        }, 1000)
      })
    },
    linkAccountWithGoogle(){
      this.isLoading = true;
      return useGAuth.loginInWithGoogle().then(data => {
        this.doLinkAccount({
          email: data.email,
          uui: data.password,
          image: this.image || data.image,
          username: this.username || data.username,
          timezone: this.timezone,
          auth: 'google'
        });
      }).catch((error) => {
        logEvents(`Error: Linked Account with Google ${error?.message }`);
        toast.error(error?.message || 'Error: Something went wrong!');
        this.isLoading = false;
      })
    },
    doLinkAccount(data) {
      // Handle signup logic here
      this.isLoading = true;
      axios.put('/auth/link_google_account', data)
          .then(async ({user}) => {
            AppStore.session = {
              ...AppStore.session,
              user: {...user}
            }
            logEvents(`Success: Linked Account with Google`);
          })
          .catch(async (error) => {
            await useGAuth.logoutFromGoogle();
            logEvents(`Server Failed: Linked Account with Google ${error?.message }`);
            toast.error(error?.message || 'Something went wrong!');
          })
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 1000)
          })
    }
  },

  mounted() {
    this.username = this.user.username;
    this.email = this.user.email;
    this.image = this.user.image;
    // this.timezone = user.timezone;
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
};
</script>
