const Storage = {
    set(key, value) {
        if (!key) {
            return;
        }
        localStorage.setItem(key, JSON.stringify(value));
    },

    get(key) {
        let value = null;
        if (!!localStorage[key]) {
            value = JSON.parse(localStorage.getItem(key)) || localStorage.getItem(key)
        }
        return value;
    },

    check(key) {
        return !!localStorage[key] || false;
    },

    remove(key) {
        localStorage.removeItem(key);
    },

    clearAll() {
        localStorage.clear();
    },
};

export default Storage;
