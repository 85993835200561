<template>
  <div class="min-h-[850px] max-sm:min-h-[70vh] flex items-center justify-center h-full justify-evenly items-center">
    <div class="flex overflow-hidden w-1/2 justify-center items-center max-sm:hidden">
      <div class="w-full p-4 rounded-md overflow-hidden">
        <img :src="PokerLogo" class="w-full h-full" />
      </div>
    </div>
    <div class="w-1/2 flex justify-center items-center max-sm:w-full">
      <div class="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 class="text-2xl font-bold mb-6 text-center">Sign Up</h2>
        <form @submit.prevent="handleSubmit">
          <div class="mb-4">
            <label for="username" class="block text-gray-700 mb-2">Username</label>
            <input
                type="text"
                id="username"
                v-model="username"
                autocomplete="username"
                class="w-full p-2 border rounded"
                :class="{'border-red-500': usernameError}"
            />
            <p v-if="usernameError" class="text-red-500 text-sm mt-1">{{ usernameError }}</p>
          </div>
          <div class="mb-4">
            <label for="email" class="block text-gray-700 mb-2">Email</label>
            <input
                type="email"
                id="email"
                v-model="email"
                autocomplete="email"
                class="w-full p-2 border rounded"
                :class="{'border-red-500': emailError}"
            />
            <p v-if="emailError" class="text-red-500 text-sm mt-1">{{ emailError }}</p>
          </div>
          <div class="mb-4">
            <label for="password" class="block text-gray-700 mb-2">Password</label>
            <div class="relative">
              <input
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  v-model="password"
                  autocomplete="off"
                  class="w-full p-2 border rounded"
                  :class="{'border-red-500': passwordError}"
              />
              <button
                  type="button"
                  @click="togglePasswordVisibility"
                  class="absolute right-5 top-3 text-gray-700 text-sm"
              >
                {{ showPassword ? 'Hide' : 'Show' }}
              </button>
            </div>
            <p v-if="passwordError" class="text-red-500 text-sm mt-1">{{ passwordError }}</p>
          </div>
          <button
              type="submit"
              :disabled="isLoading"
              class="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:opacity-50"
          >
            Sign Up
          </button>
        </form>
        <div class="mt-3 text-center text-gray-600 text-sm">
          <span>Do you have an account?</span>
          <router-link to="/login" class="text-blue-500 hover:underline">Log In</router-link>
        </div>
        <div class="or">
          <span class="or-text">OR</span>
        </div>
        <button
            @click="googleSignup"
            :disabled="isLoading"
            class="flex gap-2 justify-center items-center w-full border border-blue-500 text-gray-600 p-2 rounded hover:text-blue-600 disabled:opacity-50"
            type="button"
        >
          <img :src="GoogleIcon" alt="Google Auth" class="w-6 h-6"/>
          <span>Signup with Google</span>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import {useAuth} from "@/composable/useAuth";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import Storage from "@/library/storage";
import AppStore from "@/store/app";
import {toast} from 'vue3-toastify';
const {logEvents} = FBApp;
const useGAuth = useAuth();
export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      showPassword: false,
      usernameError: '',
      emailError: '',
      passwordError: '',
      timezone: null,
      isLoading: false,
      PokerLogo: require("@/assets/signup.svg"),
      GoogleIcon: require("@/assets/google-logo.svg")
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    validatePassword(password) {
      return password.length >= 6;
    },
    validateUsername(username) {
      return username.length >= 3;
    },
    handleSubmit() {
      this.usernameError = '';
      this.emailError = '';
      this.passwordError = '';

      if (!this.validateUsername(this.username)) {
        this.usernameError = 'Username must be at least 3 characters long';
      }

      if (!this.validateEmail(this.email)) {
        this.emailError = 'Invalid email address';
      }

      if (!this.validatePassword(this.password)) {
        this.passwordError = 'Password must be at least 6 characters long';
      }

      if (!!this.usernameError || !!this.emailError || !!this.passwordError) {
        toast.error('Error: Check required fields in form');
        return;
      }

      this.doSignUp({
        username: this.username,
        email: this.email,
        password: this.password,
        timezone: this.timezone
      })
    },

    async googleSignup() {
      this.isLoading = true;
      return useGAuth.loginInWithGoogle().then(data => {
        this.doSignUp({
          email: data.email,
          password: data.password,
          image: data.image,
          username: data.username,
          timezone: this.timezone,
          auth: 'google'
        })
      }).catch((error) => {
        console.log('error', error);
        logEvents(`Error: Google Signup Page ${error?.message}`);
        toast.error(error?.message || 'Error: Something went wrong!');
        this.isLoading = false;
      })
    },

    doSignUp(data) {
      // Handle signup logic here
      this.isLoading = true;
      axios.post('/auth/signup', data)
          .then(async ({result}) => {
            AppStore.session = result
            if (Storage.get('NEXT_ROUTE')) {
              await this.$router.replace({path: Storage.get('NEXT_ROUTE')});
              Storage.remove('NEXT_ROUTE');
              return;
            }
            logEvents(`Success: Google Signup`);
            await this.$router.replace({path: '/'});
          })
          .catch(async (error) => {
            if (data.auth === 'google') {
              await useGAuth.logoutFromGoogle();
            }
            logEvents(`Error: Signup ${error?.message}`);
            toast.error(error?.message || 'Something went wrong!');
          })
          .finally(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
              this.isLoading = false;
            }, 1000)
          })
    }
  },
  mounted() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },

  beforeMount() {
    useGAuth.watchAuthState()
  },

  beforeUnmount() {
    useGAuth.unwatchAuthState()
  }

};
</script>
