<!-- src/pages/LandingPage.vue -->
<template>
  <div class="min-h-[800px] flex flex-col items-center justify-start mt-5">
    <div class="w-full max-w-5xl py-6">
      <span class="font-bold text-xl">Users Admin List</span>
    </div>
    <div v-if="!isLoading && users?.length" class="w-full max-w-5xl bg-white p-6 rounded shadow-md">

      <table class="min-w-full bg-white">
        <thead>
        <tr>
          <th class="py-2 max-sm:text-start">Username</th>
          <th class="py-2">Email</th>
          <th class="py-2">Rooms</th>
          <th class="py-2">Timezone</th>
          <th class="py-2">Role</th>
          <th class="py-2">Image</th>
          <th class="py-2"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users" :key="user.id">
          <td class="border px-4 py-2">{{ user.username }}</td>
          <td class="border px-4 py-2">{{ user.email }}</td>
          <td class="border px-4 py-2 text-center">{{ user?.rooms?.length || 0 }}</td>
          <td class="border px-4 py-2">{{ user.timezone }}</td>
          <td class="border px-4 py-2">{{ user.role }}</td>
          <td class="border px-4 py-2">
            <div class="flex group relative w-10 h-10">
              <img v-if='user.image' :src="user.image" alt="user image" referrerpolicy="no-referrer"
                   class="rounded-full w-full h-full object-cover border border-blue-300">
              <img v-else :src="UserIcon"/>
            </div>
          </td>

          <td class="border px-4 py-2 text-center whitespace-nowrap">
            {{ moment(user.updated_at).format('YY, MMM/D, hh:mm A') }}
          </td>
          <td class="border px-4 py-2">
            <div class="flex w-full justify-center">
              <button @click="deleteUser(user.id)"
                      v-if="user?.role !== '1'"
                      class="text-red-500 hover:text-red-700 cursor-pointer">
                <TrashIcon class="w-6"/>
              </button>
              <span v-else>
                Admin
              </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <TableFooter :meta="meta" @paginate="paginate"/>

    </div>
  </div>
</template>

<script>
import TrashIcon from "@/assets/trash";
import TableFooter from "@/components/TableFooter";
import Base64 from "@/library/base64";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import AppStore from "@/store/app";
import moment from "moment";
import {toast} from "vue3-toastify";

const {logEvents} = FBApp;

export default {
  name: "usersList",
  components: {
    TrashIcon,
    TableFooter
  },
  data() {
    return {
      search: '',
      showForm: false,
      users: [],
      meta: null,
      page: 1,
      UserIcon: require('@/assets/profile-pic.png'),
      moment
    };
  },
  mounted() {
    this.fetchAllUsers();
  },

  computed: {
    isLoading() {
      return !!AppStore.isLoading;
    },
    userId() {
      return AppStore.userId;
    },
  },

  methods: {
    getUrl(user) {
      return `/user/${ Base64.encrypt(`${ user.id }?v=view`) }`;
    },
    deleteUser(id) {
      logEvents(`Delete user Id is ${ id }`);
      const isConfirm = confirm('Are you sure to delete user?');
      if (!isConfirm) {
        return;
      }
      axios.delete(`admin/users/delete/${ id }`)
          .then((data) => {
            this.fetchAllUsers();
            toast.success(data?.message || 'Deleted Successfully!')
          })
          .catch((error) => {
            toast.error(error?.message || 'Something went wrong!')
          })
    },

    fetchAllUsers() {
      AppStore.loading = true;
      const params = {
        page: this.page,
        perPage: 50
      }
      axios.get('admin/users/fetch/all', {params}).then((result) => {
        this.users = result.users;
        this.meta = result.meta;
      }).finally(() => {
        AppStore.loading = false;
      })
    },

    paginate(page) {
      this.page = page;
      this.fetchAllUsers();
    }
  },
};
</script>
