<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-md w-full max-w-2xl">
      <h2 class="text-3xl font-bold mb-6 text-center">Create new Room</h2>
      <p v-if="roomError" class="text-red-500 text-sm mt-1">{{ roomError }}</p>
      <div>
        <div class="mb-4">
          <label for="roomName" class="block text-gray-700 mb-2">Enter room name</label>
          <input
              type="text"
              id="roomName"
              v-model="roomName"
              class="w-full p-2 border rounded"
              :class="{'border-red-500': roomError}"
              maxlength="100"
              required
          />
        </div>

        <div class="mb-4 border border-gray-200 rounded p-4">
          <label class="block text-gray-700 mb-2">Customize cards values</label>
          <div class="flex flex-wrap gap-x-5">
            <div v-for="(card, index) in cards" :key="index" class="mr-4 mb-2 p-2">
              <input
                  type="checkbox"
                  :id="'card-' + index"
                  v-model="selectedCards"
                  :value="card.value"
                  disabled
                  class="mr-2"
              />
              <label :for="'card-' + index">{{ card.value }}</label>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <input type="checkbox" id="useAllCards" disabled v-model="useAllCards" @change="toggleAllCards">
          <label for="useAllCards" class="text-gray-700 ml-2">Use all cards</label>
        </div>

        <template v-if="false">
          <div class="mb-4">
            <input type="checkbox" id="enterStories" v-model="enterStories">
            <label for="enterStories" class="text-gray-700 ml-2">Do you want to enter stories in this room?</label>
          </div>

          <div class="mb-4">
            <input type="checkbox" id="confirmationSkipStories" v-model="confirmationSkipStories">
            <label for="confirmationSkipStories" class="text-gray-700 ml-2">Request confirmation when skipping
              stories?</label>
          </div>

          <div class="mb-4">
            <input type="checkbox" id="realTimeVoting" v-model="realTimeVoting">
            <label for="realTimeVoting" class="text-gray-700 ml-2">Do you want observers to see other players voting in
              real time?</label>
          </div>

          <div class="mb-4">
            <input type="checkbox" id="revealVotes" v-model="revealVotes">
            <label for="revealVotes" class="text-gray-700 ml-2">Do you want to reveal individual votes when voting completed?</label>
          </div>

          <div class="mb-4">
            <input type="checkbox" id="allowVoteChange" v-model="allowVoteChange">
            <label for="allowVoteChange" class="text-gray-700 ml-2">Allow players to change vote after scores shown?</label>
          </div>

          <div class="mb-4">
            <input type="checkbox" id="useCountdownTimer" v-model="useCountdownTimer">
            <label for="useCountdownTimer" class="text-gray-700 ml-2">Do you want to use a countdown timer?</label>
          </div>
        </template>

        <div class="flex gap-2 w-full">
          <button
              type="button"
              @click="handleCancel"
              class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 group rounded w-full p-2 disabled:opacity-75 flex justify-center items-center"
          >
            Cancel
          </button>

          <button
              type="button"
              @click="handleSubmit"
              class="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cards from "@/library/cards";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import {toast} from 'vue3-toastify';
const {logEvents} = FBApp;
export default {
  emits: ['success', 'close'],
  data() {
    return {
      roomName: '',
      roomError: '',
      cards,
      selectedCards: [],
      useAllCards: true,
      enterStories: true,
      confirmationSkipStories: false,
      realTimeVoting: true,
      revealVotes: true,
      allowVoteChange: false,
      useCountdownTimer: false,
    };
  },
  watch: {
    useAllCards(value) {
      if (value) {
        this.selectAllCards();
      } else {
        this.selectedCards = [];
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('close')
    },
    handleSubmit() {
      if (!this.roomName) {
        this.roomError = 'Room name is required';
        return;
      }

      if (this.roomName.length > 100) {
        this.roomError = 'Room name cannot exceed 100 characters';
        return;
      }

      if (this.selectedCards.length <= 1) {
        this.roomError = 'Card values is required at least 2 cards selected';
        return;
      }

      axios.post('/room/store', {
        title: this.roomName,
      }).then(({room}) => {
        toast.success('Saved Successfully!');
        this.$emit('success', room);
        logEvents(`Create Room ${room.id}`);
        this.roomError = '';
      }).catch((error) => {
        toast.error(error.message);
      });
    },

    toggleAllCards() {
      if (this.useAllCards) {
        return this.selectAllCards();
      }

      this.selectedCards = [];
    },
    selectAllCards() {
      this.selectedCards = this.cards.map(card => card.value);
    },
  },
  created() {
    if (this.useAllCards) {
      this.selectAllCards();
    }
  },
};
</script>
