const Base64 = {
  encrypt: (input) => {
    try {
      return btoa(unescape(encodeURIComponent(input)));
    } catch (e) {
      console.error('Encoding failed:', e);
      return null;
    }
  },

  decrypt: (input) => {
    try {
      return decodeURIComponent(escape(atob(input)));
    } catch (e) {
      console.error('Decoding failed:', e);
      return null;
    }
  }
};

export default Base64
