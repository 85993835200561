<script>
export default {
  name: "empty",
}
</script>

<template>
  <svg width="1071" height="602" viewBox="0 0 1071 602" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3954_7016)">
      <path d="M1071 -11H0V613H1071V-11Z" fill="white"/>
      <path d="M892.571 485.08L891.771 481.28C881.871 469.48 871.871 457.48 865.271 443.48C858.671 429.58 855.771 413.18 860.771 398.58C868.771 375.08 893.571 362.78 914.671 349.68C969.171 315.58 1009.87 263.28 1029.57 202.08C1061.97 251.48 1055.27 319.48 1024.97 370.28C994.671 421.08 944.771 457.18 892.571 485.08Z" fill="#D9DAE8"/>
      <path d="M1028.47 202.58C1028.27 200.88 1030.87 200.38 1030.87 202.08C1032.67 229.08 1029.87 256.18 1022.57 282.18C1016.27 304.98 1006.47 326.68 993.47 346.48C987.87 355.38 980.77 363.18 972.47 369.68C964.37 375.58 954.87 379.28 944.97 380.68C935.37 382.28 925.17 382.88 916.47 387.48C906.47 392.68 901.17 402.88 899.17 413.68C894.77 436.68 904.47 460.88 895.57 483.28C894.87 484.78 892.47 483.98 893.07 482.38C898.27 469.48 896.97 455.48 895.97 441.98C895.07 429.88 894.57 417.38 898.57 405.68C901.57 396.08 908.47 388.28 917.57 384.08C926.47 380.18 936.37 379.58 945.77 377.98C955.97 376.38 965.47 372.18 973.47 365.68C981.47 358.78 988.27 350.58 993.67 341.58C1019.67 300.08 1031.77 251.38 1028.47 202.58Z" fill="#e6e7eb"/>
      <path d="M1003.67 326.18C1018.17 332.78 1035.37 327.88 1044.17 314.58C1045.07 313.18 1047.17 314.68 1046.27 316.08C1036.77 330.38 1018.37 335.68 1002.77 328.68C1001.27 327.98 1002.17 325.58 1003.67 326.18Z" fill="#e6e7eb"/>
      <path d="M950.671 377.78C951.371 379.28 948.971 380.48 948.271 378.88C941.871 362.48 941.871 344.18 948.271 327.78C948.971 326.28 951.371 327.08 950.771 328.68C944.571 344.48 944.471 361.98 950.671 377.78Z" fill="#e6e7eb"/>
      <path d="M1026.57 256.58C1027.27 256.88 1027.57 257.58 1027.37 258.18C1027.07 258.88 1026.37 259.18 1025.67 258.98C1018.47 257.28 1012.67 252.28 1009.97 245.38C1009.47 243.88 1011.77 242.68 1012.37 244.28C1014.77 250.58 1020.07 255.18 1026.57 256.58Z" fill="#e6e7eb"/>
      <path d="M832.971 181.48C868.971 195.38 896.271 225.48 912.371 261.08C920.371 278.98 925.571 297.98 927.771 317.48C928.571 324.98 928.971 332.58 929.071 340.18C929.171 354.08 928.071 367.88 925.871 381.58C925.771 382.58 925.671 383.48 925.471 384.38C920.471 414.68 910.571 444.48 898.371 472.98C896.771 476.78 895.171 480.48 893.471 484.18L893.171 484.78C893.171 484.78 893.071 484.88 893.071 484.98C892.971 485.18 892.871 485.48 892.771 485.68C892.571 486.08 892.471 486.38 892.271 486.78C891.971 487.38 891.671 488.08 891.371 488.68L888.471 486.18C888.271 486.08 887.971 485.98 887.771 485.98L811.671 426.78C811.571 424.88 811.471 422.98 811.571 421.08C812.171 402.98 820.871 384.58 827.571 367.18C832.471 354.58 836.371 341.58 839.271 328.38C846.471 295.08 847.571 260.88 842.271 227.28C842.171 226.28 841.971 225.18 841.771 224.08C839.471 210.18 835.971 196.48 831.471 183.08C831.171 182.08 830.771 181.08 830.471 180.08C831.471 180.38 832.371 180.78 832.971 181.48Z" fill="#EBECF2"/>
      <path d="M829.97 181.18C828.87 179.88 830.57 177.98 831.77 179.38C849.47 199.78 863.57 223.08 873.37 248.28C882.17 270.28 887.37 293.58 888.87 317.18C889.67 327.68 888.77 338.18 886.07 348.38C883.17 357.88 877.87 366.58 870.77 373.68C864.07 380.68 856.27 387.28 852.07 396.18C847.17 406.38 849.07 417.68 853.97 427.48C864.47 448.48 886.67 461.98 893.07 485.18C893.47 486.78 890.97 487.58 890.57 485.98C886.97 472.58 877.47 462.18 868.57 451.98C860.57 442.88 852.67 433.18 848.77 421.48C845.27 412.08 846.07 401.58 850.87 392.78C855.57 384.38 863.07 377.98 869.67 370.98C876.87 363.58 881.97 354.48 884.37 344.48C886.57 334.18 887.07 323.58 885.97 313.08C881.57 264.38 862.07 218.18 829.97 181.18Z" fill="#D3D5E3"/>
      <path d="M885.371 297.28C883.771 297.68 882.971 295.18 884.571 294.78C900.171 291.38 910.871 277.08 909.871 261.18C909.771 259.48 912.371 259.38 912.471 261.08C913.571 278.18 902.071 293.48 885.371 297.28Z" fill="#D3D5E3"/>
      <path d="M873.371 367.78C874.771 368.68 873.571 370.98 872.171 370.18C857.171 360.88 846.171 346.38 841.371 329.38C840.971 327.78 843.471 326.98 843.871 328.58C848.471 344.88 858.971 358.88 873.371 367.78Z" fill="#D3D5E3"/>
      <path d="M1071 454.3V612.9H0V454.3" fill="#e6e7eb"/>
      <path d="M198.66 452.98H199.26H209.36H211.06H698.36C699.86 452.98 701.06 452.08 701.46 450.78L785.06 139.48C787.66 129.48 779.46 119.88 768.26 119.88H291.26C289.76 119.88 288.56 120.78 288.16 122.08L201.66 444.38L198.66 452.98Z" fill="#75768C"/>
      <path d="M288.561 119.88H790.061L879.461 483.88H377.961L288.561 119.88Z" fill="#9899AE"/>
      <path d="M318.86 136.18L397.06 444.48L837.46 441.48L774.36 136.68L318.86 136.18Z" fill="white"/>
      <path d="M327.261 136.28L357.861 256.98L358.761 260.58L381.261 349.38L382.161 352.98L405.461 444.58L670.961 443.08H674.661L761.061 442.58H764.761L851.161 442.08L830.261 352.88L829.361 349.28L808.561 260.48L807.661 256.88L779.461 136.78L327.261 136.28ZM638.461 260.48H724.961L742.561 349.28H656.061L638.461 260.48Z" fill="white"/>
      <path d="M520.311 178.93L534.711 251.63H463.911L449.511 178.93H520.311Z" fill="#e6e7eb"/>
      <path d="M709.062 170.48C709.962 170.28 710.962 170.88 711.162 171.88L727.962 256.98L728.662 260.58L746.262 349.38L746.962 352.98L764.662 442.58L766.662 452.68C766.862 453.58 766.262 454.58 765.262 454.78H764.862C763.962 454.78 763.262 454.18 763.062 453.28L760.962 442.48L743.262 352.78L742.562 349.18L724.962 260.38L724.262 256.78L707.562 172.38C707.362 171.38 707.962 170.48 709.062 170.48Z" fill="#9899AE"/>
      <path d="M618.761 170.48C619.661 170.28 620.661 170.88 620.861 171.88L637.661 256.98L638.361 260.58L655.961 349.38L656.661 352.98L674.461 443.08L676.361 452.68C676.561 453.58 675.961 454.58 674.961 454.78H674.561C673.661 454.78 672.961 454.18 672.761 453.28L670.761 442.98L652.961 352.78L652.261 349.18L634.661 260.38L633.961 256.78L617.261 172.38C617.061 171.38 617.761 170.48 618.761 170.48Z" fill="#9899AE"/>
      <path d="M582.76 453.38L527.16 172.48C526.96 171.58 527.56 170.58 528.56 170.38C529.56 170.18 530.46 170.78 530.66 171.78L586.26 452.68C586.46 453.58 585.86 454.58 584.86 454.78H584.46C583.66 454.78 582.86 454.18 582.76 453.38Z" fill="#9899AE"/>
      <path d="M492.56 453.38L436.96 172.48C436.76 171.58 437.36 170.58 438.36 170.38C439.36 170.18 440.26 170.78 440.46 171.78L496.06 452.68C496.26 453.58 495.66 454.58 494.66 454.78H494.26C493.36 454.78 492.66 454.18 492.56 453.38Z" fill="#9899AE"/>
      <path d="M357.861 256.98C357.961 256.88 358.061 256.88 358.261 256.88H816.561C817.561 256.88 818.361 257.68 818.361 258.68C818.361 259.68 817.561 260.48 816.561 260.48H358.261C357.261 260.48 356.461 259.68 356.461 258.68C356.461 257.78 356.961 257.08 357.861 256.98Z" fill="#9899AE"/>
      <path d="M335.961 172.08C335.961 171.08 336.761 170.28 337.761 170.28H794.961C795.961 170.28 796.761 171.08 796.761 172.08C796.761 173.08 795.961 173.88 794.961 173.88H337.761C336.761 173.88 335.961 173.08 335.961 172.08Z" fill="#9899AE"/>
      <path d="M381.16 349.28H832.86C833.86 349.28 834.66 350.08 834.66 351.08C834.66 352.08 833.86 352.88 832.86 352.88H381.16C380.16 352.88 379.36 352.08 379.36 351.08C379.36 350.08 380.16 349.18 381.16 349.28Z" fill="#9899AE"/>
      <path d="M736.961 217.58C736.961 216.58 737.761 215.78 738.761 215.78H774.461C775.461 215.78 776.261 216.58 776.261 217.58C776.261 218.58 775.461 219.38 774.461 219.38H738.761C737.761 219.38 736.861 218.58 736.961 217.58Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M561.061 201.78C561.061 200.78 561.861 199.98 562.861 199.98H598.561C599.561 199.98 600.361 200.78 600.361 201.78C600.361 202.78 599.561 203.58 598.561 203.58H562.861C561.861 203.58 561.061 202.78 561.061 201.78Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M565.161 217.58C565.161 216.58 565.961 215.78 566.961 215.78H602.661C603.661 215.78 604.461 216.58 604.461 217.58C604.461 218.58 603.661 219.38 602.661 219.38H566.961C565.961 219.38 565.061 218.58 565.161 217.58Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M578.761 296.48C578.761 295.48 579.561 294.68 580.561 294.68H616.261C617.261 294.68 618.061 295.48 618.061 296.48C618.061 297.48 617.261 298.28 616.261 298.28H580.561C579.561 298.28 578.661 297.48 578.761 296.48Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M582.761 312.18C582.761 311.18 583.561 310.38 584.561 310.38H620.261C621.261 310.38 622.061 311.18 622.061 312.18C622.061 313.18 621.261 313.98 620.261 313.98H584.561C583.561 313.98 582.761 313.18 582.761 312.18Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M688.76 389.88C688.76 388.88 689.56 388.08 690.56 388.08H726.26C727.26 388.08 728.06 388.88 728.06 389.88C728.06 390.88 727.26 391.68 726.26 391.68H690.56C689.56 391.68 688.76 390.88 688.76 389.88Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M692.861 405.68C692.861 404.68 693.661 403.88 694.661 403.88H730.361C731.361 403.88 732.161 404.68 732.161 405.68C732.161 406.68 731.361 407.48 730.361 407.48H694.661C693.661 407.48 692.861 406.68 692.861 405.68Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M417.961 392.88C417.961 391.88 418.761 391.08 419.761 391.08H455.461C456.461 391.08 457.261 391.88 457.261 392.88C457.261 393.88 456.461 394.68 455.461 394.68H419.761C418.761 394.68 417.961 393.88 417.961 392.88Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M422.061 408.68C422.061 407.68 422.861 406.88 423.861 406.88H459.561C460.561 406.88 461.361 407.68 461.361 408.68C461.361 409.68 460.561 410.48 459.561 410.48H423.861C422.861 410.48 422.061 409.68 422.061 408.68Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M598.561 389.88C598.561 388.88 599.361 388.08 600.361 388.08H636.061C637.061 388.08 637.861 388.88 637.861 389.88C637.861 390.88 637.061 391.68 636.061 391.68H600.361C599.361 391.68 598.561 390.88 598.561 389.88Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M602.66 405.68C602.66 404.68 603.46 403.88 604.46 403.88H640.16C641.16 403.88 641.96 404.68 641.96 405.68C641.96 406.68 641.16 407.48 640.16 407.48H604.46C603.46 407.48 602.66 406.68 602.66 405.68Z" fill="#E3E2ED" stroke="#E3E2ED" stroke-width="1.31" stroke-miterlimit="10"/>
      <path d="M492.061 382.48L520.161 399.38L499.961 418.38C497.961 420.28 497.861 423.38 499.761 425.38C500.661 426.38 501.961 426.98 503.361 426.98C504.661 426.98 505.861 426.58 506.761 425.68L528.961 404.78L560.261 423.58C560.961 424.08 561.861 424.28 562.761 424.28C564.461 424.28 566.061 423.38 566.961 421.88C568.361 419.48 567.561 416.48 565.261 415.08L536.361 397.78L553.161 381.98C554.161 381.08 554.761 379.88 554.761 378.58C554.861 377.28 554.361 376.08 553.461 375.08C552.561 374.08 551.361 373.48 550.061 373.48C548.761 373.38 547.561 373.88 546.561 374.78L527.761 392.38L497.261 374.08C494.861 372.68 491.861 373.48 490.461 375.78C489.061 378.18 489.761 381.18 492.061 382.48Z" fill="#6e33e6"/>
      <path d="M768.571 160.46C773.597 160.46 777.671 156.386 777.671 151.36C777.671 146.334 773.597 142.26 768.571 142.26C763.545 142.26 759.471 146.334 759.471 151.36C759.471 156.386 763.545 160.46 768.571 160.46Z" fill="#EBECF2"/>
      <path d="M681.36 142.28C686.36 142.28 690.46 146.38 690.46 151.38C690.46 156.38 686.36 160.48 681.36 160.48C676.36 160.48 672.26 156.38 672.26 151.38C672.26 146.38 676.36 142.28 681.36 142.28Z" fill="#EBECF2"/>
      <path d="M600.622 157.811C604.176 154.257 604.176 148.495 600.622 144.941C597.069 141.388 591.307 141.388 587.753 144.941C584.199 148.495 584.199 154.257 587.753 157.811C591.307 161.364 597.069 161.364 600.622 157.811Z" fill="#EBECF2"/>
      <path d="M509.081 160.225C513.972 159.069 517.001 154.168 515.846 149.276C514.69 144.385 509.788 141.357 504.897 142.512C500.006 143.667 496.978 148.569 498.133 153.46C499.288 158.351 504.19 161.38 509.081 160.225Z" fill="#EBECF2"/>
      <path d="M428.161 154.838C430.085 150.195 427.88 144.871 423.236 142.948C418.593 141.025 413.27 143.23 411.347 147.873C409.423 152.516 411.628 157.839 416.272 159.763C420.915 161.686 426.238 159.481 428.161 154.838Z" fill="#EBECF2"/>
      <path d="M351.661 154.858C353.585 150.214 351.38 144.891 346.736 142.968C342.093 141.045 336.77 143.25 334.847 147.893C332.923 152.536 335.128 157.859 339.772 159.782C344.415 161.706 349.738 159.501 351.661 154.858Z" fill="#EBECF2"/>
      <path d="M341.56 151.58C339.66 136.68 332.06 97.38 319.86 94.58C317.26 93.98 315.06 94.38 313.26 95.88C307.46 100.58 306.46 114.68 306.56 119.78C306.56 120.88 305.76 121.68 304.76 121.68C303.66 121.68 302.86 120.88 302.86 119.88C302.76 119.08 302.56 99.88 310.86 93.08C313.56 90.88 316.86 90.28 320.56 91.08C337.36 94.88 344.36 145.38 345.06 151.18C345.16 152.18 344.46 153.08 343.46 153.18H343.26C342.36 153.18 341.56 152.58 341.56 151.58Z" fill="#4d07e0" stroke="#4d07e0" stroke-width="3.11" stroke-miterlimit="10"/>
      <path d="M418.06 151.58C416.16 136.68 408.56 97.38 396.36 94.58C393.76 93.98 391.56 94.38 389.76 95.88C383.96 100.58 382.96 114.68 383.06 119.78C383.06 120.88 382.26 121.68 381.26 121.68C380.16 121.68 379.36 120.88 379.36 119.88C379.26 119.08 379.06 99.88 387.36 93.08C390.06 90.88 393.36 90.28 397.06 91.08C413.86 94.88 420.86 145.38 421.56 151.18C421.66 152.18 420.96 153.08 419.96 153.18H419.76C418.86 153.18 418.06 152.58 418.06 151.58Z" fill="#4d07e0" stroke="#4d07e0" stroke-width="3.11" stroke-miterlimit="10"/>
      <path d="M505.261 151.58C503.361 136.68 495.761 97.38 483.561 94.58C480.961 93.98 478.761 94.38 476.961 95.88C471.161 100.58 470.161 114.68 470.261 119.78C470.261 120.88 469.461 121.68 468.461 121.68C467.461 121.68 466.561 120.88 466.561 119.88C466.461 119.08 466.261 99.88 474.561 93.08C477.261 90.88 480.561 90.28 484.261 91.08C501.061 94.88 508.061 145.38 508.761 151.18C508.861 152.18 508.161 153.08 507.161 153.18H506.961C506.061 153.18 505.261 152.58 505.261 151.58Z" fill="#4d07e0" stroke="#4d07e0" stroke-width="3.11" stroke-miterlimit="10"/>
      <path d="M592.461 151.58C590.561 136.68 582.961 97.38 570.761 94.58C568.161 93.98 565.961 94.38 564.161 95.88C558.361 100.58 557.361 114.68 557.461 119.78C557.461 120.88 556.661 121.68 555.661 121.68C554.661 121.68 553.761 120.88 553.761 119.88C553.661 119.08 553.461 99.88 561.761 93.08C564.461 90.88 567.761 90.28 571.461 91.08C588.261 94.88 595.261 145.38 595.961 151.18C596.061 152.18 595.361 153.08 594.361 153.18H594.161C593.261 153.18 592.461 152.58 592.461 151.58Z" fill="#4d07e0" stroke="#4d07e0" stroke-width="3.11" stroke-miterlimit="10"/>
      <path d="M679.261 151.58C677.361 136.68 669.761 97.38 657.561 94.58C654.961 93.98 652.761 94.38 650.961 95.88C645.161 100.58 644.161 114.68 644.261 119.78C644.261 120.88 643.461 121.68 642.461 121.68C641.361 121.68 640.561 120.88 640.561 119.88C640.461 119.08 640.261 99.88 648.561 93.08C651.261 90.88 654.561 90.28 658.261 91.08C675.061 94.88 682.061 145.38 682.761 151.18C682.861 152.18 682.161 153.08 681.161 153.18H680.961C680.061 153.18 679.261 152.58 679.261 151.58Z" fill="#4d07e0" stroke="#4d07e0" stroke-width="3.11" stroke-miterlimit="10"/>
      <path d="M766.76 151.58C764.86 136.68 757.26 97.38 745.06 94.58C742.46 93.98 740.26 94.38 738.46 95.88C732.66 100.58 731.66 114.68 731.76 119.78C731.76 120.88 730.96 121.68 729.96 121.68C728.96 121.68 728.16 120.88 728.16 119.88C728.06 119.08 727.86 99.88 736.16 93.08C738.86 90.88 742.16 90.28 745.86 91.08C762.66 94.88 769.66 145.38 770.36 151.18C770.46 152.18 769.76 153.08 768.76 153.18H768.56C767.66 153.18 766.86 152.58 766.76 151.58Z" fill="#4d07e0" stroke="#4d07e0" stroke-width="3.11" stroke-miterlimit="10"/>
      <path d="M700.46 178.93L714.86 251.63H644.06L629.66 178.93H700.46Z" fill="#6e33e6"/>
      <path d="M442.96 268.48L457.26 341.18H386.46L372.16 268.48H442.96Z" fill="#e6e7eb"/>
      <path d="M608.661 264.38C607.661 263.98 606.561 264.78 606.261 266.08C605.961 267.08 606.661 267.98 607.561 268.38C609.061 268.98 610.461 269.68 611.861 270.38C623.461 276.48 633.061 287.58 636.061 300.48C637.561 306.98 637.361 314.18 634.561 320.28C631.761 326.38 626.561 330.98 620.361 333.48C607.861 338.48 592.561 335.58 581.261 328.78C575.461 325.38 569.861 320.48 566.761 314.38C563.461 307.88 562.861 300.28 565.361 293.38C567.861 286.78 573.161 282.38 579.761 280.38C587.361 278.08 595.261 279.28 602.361 282.38C603.361 282.78 604.361 282.68 604.961 281.68C605.361 280.88 605.161 279.48 604.261 279.08C602.261 278.18 600.161 277.38 598.061 276.78C590.061 274.48 581.561 274.78 574.061 278.48C566.961 281.98 562.261 288.48 560.661 296.18C559.061 303.98 560.861 312.68 565.461 319.28C569.761 325.38 576.061 330.38 582.761 333.68C595.961 340.28 613.161 342.18 626.261 334.48C632.761 330.68 637.561 324.58 639.561 317.28C641.661 310.08 641.061 302.08 638.561 295.08C633.561 281.18 622.361 269.88 608.661 264.38Z" fill="#6e33e6"/>
      <path d="M194.06 337.68C188.96 296.98 163.76 256.88 124.96 243.68C140.26 285.58 140.26 331.48 124.96 373.38C118.96 389.48 110.66 406.88 116.26 423.08C119.76 433.18 128.26 440.98 137.86 445.78C147.36 450.58 157.96 452.88 168.46 455.18L170.56 456.88C187.16 419.38 199.16 378.38 194.06 337.68Z" fill="#B3B5CC"/>
      <path d="M124.66 244.48C147.26 269.78 161.26 301.58 164.76 335.38C165.66 342.68 165.36 349.98 163.96 357.18C162.36 364.08 158.96 370.48 154.06 375.68C149.56 380.58 144.46 385.08 141.26 390.98C138.06 397.18 137.56 404.38 140.06 410.88C142.86 418.98 148.46 425.58 154.16 431.88C160.46 438.88 167.06 445.98 169.76 455.18C170.06 456.28 171.76 455.68 171.46 454.58C166.76 438.48 151.26 429.38 143.76 414.98C140.26 408.28 138.86 400.38 142.06 393.28C144.86 387.08 150.16 382.38 154.76 377.48C159.66 372.48 163.16 366.38 165.16 359.78C166.96 352.68 167.46 345.38 166.76 338.08C165.46 321.68 161.56 305.68 155.36 290.48C148.26 273.08 138.26 257.08 125.76 243.08C125.06 242.18 123.86 243.58 124.66 244.48Z" fill="#DCDDE8"/>
      <path d="M164.26 324.38C175.76 321.58 183.56 310.88 182.66 298.98C182.56 297.78 180.76 297.88 180.86 299.08C181.76 310.08 174.46 320.08 163.66 322.68C162.56 322.98 163.16 324.68 164.26 324.38Z" fill="#DCDDE8"/>
      <path d="M156.66 373.38C146.56 367.38 139.16 357.78 135.76 346.48C135.46 345.38 133.76 345.98 134.06 347.08C137.56 358.78 145.26 368.78 155.86 374.98C156.86 375.58 157.66 373.98 156.66 373.38Z" fill="#DCDDE8"/>
      <path d="M146.66 274.78C142.46 276.78 137.56 276.48 133.66 274.08C132.66 273.48 131.86 275.08 132.86 275.68C137.26 278.28 142.66 278.58 147.26 276.48C147.76 276.28 147.96 275.78 147.86 275.38C147.56 274.88 147.06 274.68 146.66 274.78Z" fill="#DCDDE8"/>
      <path d="M6.75977 316.38C7.35977 316.78 7.95977 317.18 8.55977 317.58C16.7598 322.98 24.4598 328.98 31.6598 335.58C32.2598 336.08 32.7598 336.58 33.3598 337.08C50.5598 353.18 64.5598 372.38 74.7598 393.68C78.7598 402.18 82.1598 410.88 84.8598 419.88C88.5598 432.28 91.6598 445.98 99.0598 456.18C99.8598 457.28 100.66 458.28 101.56 459.28H168.46C168.66 459.18 168.76 459.18 168.96 459.08L171.66 459.18C171.56 458.68 171.46 458.18 171.36 457.78C171.26 457.48 171.26 457.28 171.16 456.98C171.16 456.78 171.06 456.58 171.06 456.48V456.28C171.06 456.08 170.96 455.98 170.96 455.78C170.26 453.08 169.56 450.28 168.86 447.58C163.36 426.78 156.16 406.28 145.96 387.48C145.66 386.88 145.36 386.38 145.06 385.78C140.46 377.38 135.16 369.28 129.26 361.78C125.96 357.68 122.56 353.68 118.86 349.88C109.36 340.08 98.4598 331.88 86.3598 325.58C62.3598 312.88 34.6598 308.08 8.95977 315.78C8.05977 315.98 7.35977 316.18 6.75977 316.38Z" fill="#e6e7eb"/>
      <path d="M6.85992 317.18C40.1599 323.78 70.5599 340.68 93.6599 365.58C98.7599 370.88 102.96 376.88 106.16 383.48C109.06 389.98 110.16 397.18 109.36 404.18C108.76 410.78 107.36 417.48 108.36 424.18C109.46 431.08 113.56 437.08 119.46 440.78C126.56 445.58 135.06 447.48 143.36 449.08C152.56 450.88 162.16 452.58 169.86 458.28C170.76 458.98 171.86 457.48 170.86 456.78C157.46 446.78 139.56 448.88 124.86 441.78C118.06 438.48 112.16 433.08 110.46 425.48C108.96 418.78 110.46 411.88 111.06 405.18C111.96 398.28 111.16 391.28 108.66 384.78C105.86 378.08 101.86 371.88 96.9599 366.48C86.0599 354.18 73.3599 343.68 59.1599 335.38C43.0599 325.78 25.4599 318.98 7.05991 315.38C5.85991 315.18 5.75992 316.98 6.85992 317.18Z" fill="white"/>
      <path d="M86.6598 357.18C94.1598 347.98 93.9598 334.68 86.0598 325.78C85.2598 324.88 83.8598 326.08 84.6598 326.98C91.9598 335.28 92.1598 347.68 85.1598 356.18C84.4598 356.98 85.9598 358.08 86.6598 357.18Z" fill="white"/>
      <path d="M110.06 400.88C98.3599 402.18 86.6599 398.98 77.2599 391.98C76.3599 391.28 75.2599 392.78 76.2599 393.48C86.0599 400.78 98.2599 404.08 110.46 402.68C111.56 402.58 111.26 400.78 110.06 400.88Z" fill="white"/>
      <path d="M42.66 328.18C40.56 332.28 36.46 334.98 31.86 335.48C30.66 335.58 31.06 337.38 32.16 337.28C37.26 336.78 41.66 333.68 44.06 329.28C44.36 328.88 44.26 328.28 43.86 327.98C43.56 327.68 42.96 327.78 42.66 328.18Z" fill="white"/>
      <path d="M245.59 508.2C253.09 497.9 248.79 482.4 234.79 475.3C220.79 468.2 205.79 473.9 201.89 486.1L186.29 535.1C184.79 539.8 186.89 551.7 194.19 555.5C201.49 559.2 212.39 553.8 215.29 549.8L245.59 508.2Z" fill="#253455"/>
      <path d="M336.59 508.2C329.09 497.9 333.39 482.4 347.39 475.3C361.39 468.2 376.39 473.9 380.29 486.1L395.89 535.1C397.39 539.8 395.29 551.7 387.99 555.5C380.69 559.2 369.79 553.8 366.89 549.8L336.59 508.2Z" fill="#253455"/>
      <path d="M288.39 243.3C213.49 243.3 152.79 304 152.79 378.9C152.79 453.8 213.49 514.5 288.39 514.5C363.29 514.5 423.99 453.8 423.99 378.9C423.99 304 363.29 243.3 288.39 243.3ZM288.39 489.2C227.49 489.2 178.09 439.8 178.09 378.9C178.09 318 227.49 268.6 288.39 268.6C349.29 268.6 398.69 318 398.69 378.9C398.69 439.8 349.29 489.2 288.39 489.2Z" fill="#253455"/>
      <path d="M288.39 489.2C349.307 489.2 398.69 439.817 398.69 378.9C398.69 317.983 349.307 268.6 288.39 268.6C227.473 268.6 178.09 317.983 178.09 378.9C178.09 439.817 227.473 489.2 288.39 489.2Z" fill="#EEEEF3"/>
      <path d="M288.39 304.5C286.59 304.5 285.09 303 285.09 301.2V288.2C285.09 286.4 286.59 284.9 288.39 284.9C290.19 284.9 291.69 286.4 291.69 288.2V301.2C291.69 303 290.19 304.5 288.39 304.5Z" fill="#253455"/>
      <path d="M288.39 473.6C286.59 473.6 285.09 472.1 285.09 470.3V457.3C285.09 455.5 286.59 454 288.39 454C290.19 454 291.69 455.5 291.69 457.3V470.3C291.69 472.1 290.19 473.6 288.39 473.6Z" fill="#253455"/>
      <path d="M244.017 295.479L238.301 298.779L248.101 315.753L253.817 312.453L244.017 295.479Z" fill="#253455"/>
      <path d="M331.09 459.8L324.59 448.5C323.69 446.9 324.19 444.9 325.79 444C327.39 443.1 329.39 443.6 330.29 445.2L336.79 456.5C337.69 458.1 337.19 460.1 335.59 461C333.99 461.9 331.99 461.3 331.09 459.8Z" fill="#253455"/>
      <path d="M219.19 343.1L207.89 336.6C206.29 335.7 205.79 333.7 206.69 332.1C207.59 330.5 209.59 330 211.19 330.9L222.49 337.4C224.09 338.3 224.59 340.3 223.69 341.9C222.79 343.5 220.69 344 219.19 343.1Z" fill="#253455"/>
      <path d="M365.79 427.4L354.19 420.7C352.69 419.8 352.19 417.8 353.09 416.3C353.99 414.9 355.89 414.4 357.29 415.2L368.89 421.9C370.39 422.8 370.89 424.8 369.99 426.3C369.19 427.8 367.29 428.2 365.79 427.4Z" fill="#253455"/>
      <path d="M210.39 382.5H197.39C195.59 382.5 194.09 381 194.09 379.2C194.09 377.4 195.59 375.9 197.39 375.9H210.39C212.19 375.9 213.69 377.4 213.69 379.2C213.69 381.1 212.19 382.5 210.39 382.5Z" fill="#253455"/>
      <path d="M379.39 382.5H366.39C364.59 382.5 363.09 381 363.09 379.2C363.09 377.4 364.59 375.9 366.39 375.9H379.39C381.19 375.9 382.69 377.4 382.69 379.2C382.69 381.1 381.29 382.5 379.39 382.5Z" fill="#253455"/>
      <path d="M206.689 426.3C205.789 424.8 206.289 422.8 207.789 421.9L219.389 415.2C220.889 414.4 222.689 414.8 223.589 416.3C224.489 417.8 223.989 419.8 222.489 420.7L210.889 427.4C209.489 428.2 207.589 427.8 206.689 426.3Z" fill="#253455"/>
      <path d="M353.089 341.5C352.189 339.9 352.689 337.9 354.289 337L365.589 330.5C367.189 329.6 369.189 330.1 370.089 331.7C370.989 333.3 370.489 335.3 368.889 336.2L357.589 342.7C355.989 343.6 353.989 343.1 353.089 341.5Z" fill="#253455"/>
      <path d="M241.189 461C239.589 460.1 239.089 458.1 239.989 456.5L246.489 445.2C247.389 443.6 249.389 443.1 250.989 444C252.589 444.9 253.089 446.9 252.189 448.5L245.689 459.8C244.789 461.3 242.789 461.9 241.189 461Z" fill="#253455"/>
      <path d="M325.689 314.5C324.089 313.6 323.589 311.6 324.489 310L330.989 298.7C331.889 297.1 333.889 296.6 335.489 297.5C337.089 298.4 337.589 300.4 336.689 302L330.189 313.3C329.289 314.9 327.289 315.4 325.689 314.5Z" fill="#253455"/>
      <path d="M263.29 253.7C244.49 258.3 226.89 266.9 211.69 278.9C197.29 290.3 185.19 304.6 176.39 320.8C168.49 335.3 165.39 347 163.99 352.8" stroke="#F5F6F6" stroke-width="1.62" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M280.59 377.3L216.19 433.4C214.99 434.5 212.99 433.8 212.59 432.2L210.39 422.6C209.99 420.7 207.49 420.3 206.49 421.9L165.19 482.9C163.89 484.8 165.99 487.1 167.99 486.1L233.09 453.5C234.69 452.7 234.69 450.5 233.19 449.6L223.09 443.7C221.79 443 221.59 441.3 222.69 440.2L283.59 380.4C284.39 379.6 284.49 378.2 283.69 377.3C282.79 376.6 281.39 376.5 280.59 377.3Z" fill="#6e33e6"/>
      <path d="M223.59 454.3C223.59 454.5 223.59 454.6 223.39 454.7C222.79 454.9 204.19 464.3 173.49 479.9C172.99 480.2 172.39 480 171.99 479.6C171.59 479.2 171.59 478.6 171.89 478.1L181.09 465.4C181.19 465.3 181.39 465.2 181.49 465.3C181.59 465.4 181.69 465.6 181.59 465.7L172.39 478.4C172.19 478.7 172.29 479 172.39 479.1C172.49 479.3 172.79 479.4 173.09 479.2C187.89 471.7 222.29 454.3 222.99 454C223.29 454 223.49 454.1 223.59 454.3Z" fill="url(#paint0_linear_3954_7016)" stroke="#F79D8E" stroke-width="0.28" stroke-miterlimit="10"/>
      <path d="M287.39 381.4L359.59 362.1C361.19 361.7 362.69 363.2 362.29 364.8L359.89 374.3C359.39 376.2 361.39 377.7 363.09 376.8L427.59 341.3C429.59 340.2 428.79 337.2 426.59 337.2L353.79 336.6C352.09 336.6 350.99 338.5 351.89 340L358.29 349.8C359.09 351 358.49 352.6 357.09 353.1L286.29 377.3C285.19 377.7 284.49 378.9 284.89 380C285.09 381 286.29 381.7 287.39 381.4Z" fill="#6e33e6"/>
      <path d="M405.19 349.6C405.09 349.6 404.89 349.6 404.89 349.4C404.79 349.2 404.89 349.1 404.99 349L418.99 341.9C419.29 341.7 419.39 341.5 419.29 341.2C419.19 341 419.09 340.8 418.69 340.8C402.09 340.7 363.49 340.6 362.79 340.5C362.59 340.5 362.49 340.3 362.49 340.2C362.49 340.1 362.69 339.9 362.79 339.9C363.69 340 405.79 340.1 418.69 340.2C419.29 340.2 419.69 340.6 419.89 341.1C419.99 341.7 419.79 342.2 419.29 342.5L405.19 349.6Z" fill="url(#paint1_linear_3954_7016)" stroke="#F79D8E" stroke-width="0.28" stroke-miterlimit="10"/>
      <path d="M295.59 375.2C296.89 380.7 293.49 386.1 287.99 387.4C282.49 388.7 277.09 385.3 275.79 379.8C274.49 374.3 277.89 368.9 283.39 367.6C288.89 366.4 294.29 369.8 295.59 375.2Z" fill="#181A46"/>
      <path d="M301.391 220.774L273.591 220.803L273.603 232.503L301.403 232.474L301.391 220.774Z" fill="#253455"/>
      <path d="M294.292 232.482L280.692 232.496L280.704 243.496L294.304 243.482L294.292 232.482Z" fill="#253455"/>
      <path d="M294.289 234.5L280.689 235.4V232.5H294.289V234.5Z" fill="#181A46"/>
      <path d="M422.59 227.1C422.59 228 422.29 228.9 421.69 229.5L378.09 277.1C376.29 275.5 374.39 274 372.59 272.5L416.49 224.6C417.79 223.1 420.09 223 421.59 224.4C422.19 225.1 422.59 226.1 422.59 227.1Z" fill="#D55028"/>
      <path d="M379.99 275L375.19 269.5L376.09 268.5L381.39 273.4L379.99 275Z" fill="#BF4927"/>
      <path d="M420.39 309L337.29 232.8C358.29 209.8 393.99 208.3 416.99 229.3C439.89 250.4 441.39 286.1 420.39 309Z" fill="#6e33e6"/>
      <path opacity="0.5" d="M418.49 231.1C398.69 215.9 367.79 220.6 347.69 242.5L342.49 237.7C363.49 214.7 396.39 210.7 415.99 228.6C416.89 229.4 417.69 230.2 418.49 231.1Z" fill="white"/>
      <path d="M200.589 268.7L196.689 275.2L195.189 273.6L200.589 268.7Z" fill="#BF4927"/>
      <path d="M162.489 227.3L200.589 268.7L195.289 273.6L157.189 232.2L154.989 229.8C154.389 229.1 153.989 228.2 153.989 227.3C153.989 226.3 154.389 225.4 155.189 224.6C156.689 223.3 158.989 223.3 160.289 224.8L162.489 227.3Z" fill="#D55028"/>
      <path d="M204.089 272.6C202.189 274.1 200.389 275.7 198.489 277.3L196.689 275.3L200.489 268.8L204.089 272.6Z" fill="#D55028"/>
      <path d="M239.39 233L200.59 268.7L195.29 273.6L156.49 309.3C136.19 287.3 136.79 253.5 157.19 232.2C157.99 231.3 158.89 230.5 159.79 229.7C160.69 228.9 161.59 228.1 162.49 227.3C185.39 208.7 219.09 211 239.39 233Z" fill="#6e33e6"/>
      <path opacity="0.5" d="M158.09 231.4C177.79 216.1 208.79 220.8 228.89 242.6L234.09 237.8C212.99 214.9 180.09 210.9 160.59 228.8C159.69 229.7 158.89 230.5 158.09 231.4Z" fill="white"/>
      <path d="M754.239 283.97C759.069 281.73 764.769 283.9 766.969 288.8C767.199 289.32 767.389 289.87 767.529 290.42L844.119 302.7L833.159 316.87L759.969 302.57C754.719 303.57 749.679 300.06 748.689 294.73C747.869 290.27 750.159 285.82 754.239 283.97Z" fill="#F9B194"/>
      <path d="M857.34 491.5C857.34 491.5 870.93 510.2 874 513.32C877.07 516.44 877.95 528.01 877.95 528.01L872.69 540.48L875.76 546.71L899.44 522.66L874.01 484.36L857.35 491.48L857.34 491.5Z" fill="#F9B194"/>
      <path d="M799.459 377.06C799.459 377.06 848.659 529.31 853.929 532.87C859.189 536.43 885.109 514.07 892.559 508.73C900.009 503.39 836.299 401.73 836.299 401.73C846.389 368.33 841.559 360.58 841.559 360.58L799.459 377.06Z" fill="#161944"/>
      <path d="M876.628 543.15C876.628 543.15 876.628 537.81 874.938 535.14C873.248 532.47 870.058 544.49 870.058 544.49C870.058 544.49 850.768 549.83 859.538 555.62C868.308 561.41 894.178 543.15 901.638 538.25C909.088 533.35 906.458 527.56 902.518 523.56L894.848 515.77L876.648 543.16L876.628 543.15Z" fill="#161944"/>
      <path d="M783.669 501.29C783.669 501.29 779.719 512.87 776.219 524C772.719 535.13 756.049 540.92 756.049 540.92L752.979 548.94L762.189 553.39L788.939 546.71C788.939 546.71 788.059 537.8 788.059 534.69C788.059 531.58 802.089 505.3 802.089 505.3L783.669 501.29Z" fill="#F9B194"/>
      <path d="M818.309 243.91C828.482 243.91 836.729 235.538 836.729 225.21C836.729 214.882 828.482 206.51 818.309 206.51C808.136 206.51 799.889 214.882 799.889 225.21C799.889 235.538 808.136 243.91 818.309 243.91Z" fill="#F9B194"/>
      <path d="M828.398 235.01C828.398 237.68 841.558 258.17 841.558 258.17L822.288 266.9L813.498 241.24L828.408 235.01H828.398Z" fill="#F4A17F"/>
      <path d="M810.86 332.53C803.33 332.49 797.16 338.57 796.97 346.21L796.39 369.49L793.32 374.83C793.32 374.83 774.43 517.85 774.43 522.31C774.43 526.77 793.18 539.04 804.15 538.15C809.57 537.71 821.73 487.11 826.56 459.42C829.99 439.7 833.35 418.24 836.71 402.4C837.83 396.69 839.31 391.05 841.12 385.53C850.77 358.37 834.1 332.54 834.1 332.54H810.86V332.53Z" fill="#161944"/>
      <path d="M759.769 546.93C759.769 546.93 757.359 547.6 759.989 546.71C762.619 545.82 761.739 540.92 761.639 538.43C761.539 535.94 735.879 544.04 735.879 550.27C735.879 556.5 768.769 556.5 778.409 555.61C788.049 554.72 790.249 555.61 791.569 549.38C792.889 543.15 787.839 534.46 787.839 534.46L759.779 546.93H759.769Z" fill="#161944"/>
      <path d="M832.36 344.94C828.93 344.94 821.56 336 817.7 339.92C814.89 342.77 812.51 345.51 810.41 347.93C804.38 354.87 799.1 361.22 795.44 356.66C794.89 355.97 796.75 357.56 795.2 356.49C793.88 355.58 793.27 353.92 793.69 352.35C799.01 335.43 802.32 319.22 798.09 305.68C797.78 304.61 797.63 303.49 797.65 302.37C797.65 302.21 797.65 302.04 797.63 301.88C797.41 299.38 797.46 290.43 807.56 280.18C816.79 270.81 818.56 265.05 818.79 262.45C818.84 261.84 833.72 246.17 833.72 246.17C835.32 245.49 837.18 246.02 838.2 247.45L842.96 254.1C848.14 264.51 850.4 276.17 849.49 287.8C848.2 302.9 845.96 332.75 844.12 343.52C843.97 344.5 843.46 345.39 842.69 346C841.61 346.85 840.16 347.03 838.91 346.45C836.86 345.48 834.64 344.97 832.38 344.94H832.36Z" fill="#4d07e0"/>
      <path d="M813.889 225.93L817.199 222.38C816.919 241.06 799.569 251.49 799.569 251.49L838.329 247.57L839.309 242.61L841.329 246.69L846.059 245.47L847.419 242.45L850.339 244.06L844.349 220.04C838.429 197.12 820.389 200.48 820.389 200.48C795.129 192.46 798.219 216.93 798.219 216.93L813.889 225.93Z" fill="#3C2415"/>
      <path opacity="0.2" d="M828.649 465.47L835.679 402.35L827.039 460.82L828.649 465.47Z" fill="#010101"/>
      <path d="M841.158 210.62C846.537 210.62 850.898 206.188 850.898 200.72C850.898 195.252 846.537 190.82 841.158 190.82C835.779 190.82 831.418 195.252 831.418 200.72C831.418 206.188 835.779 210.62 841.158 210.62Z" fill="#3C2415"/>
      <path d="M839.608 208.74C839.408 208.74 839.208 208.74 838.998 208.74H838.978C833.608 208.38 829.538 203.67 829.888 198.22C830.238 192.77 834.878 188.63 840.248 188.99C840.458 189 840.648 189.03 840.858 189.06C835.478 189.04 831.118 193.46 831.098 198.91C831.088 203.9 834.738 208.12 839.608 208.74Z" fill="#3C2415"/>
      <path d="M816.798 231.03C818.483 231.03 819.848 229.092 819.848 226.7C819.848 224.309 818.483 222.37 816.798 222.37C815.114 222.37 813.748 224.309 813.748 226.7C813.748 229.092 815.114 231.03 816.798 231.03Z" fill="#F9B194"/>
      <path d="M780.328 370.27L767.808 379.27C763.818 382.13 762.878 387.74 765.698 391.79C766.098 392.36 766.558 392.88 767.078 393.35C770.748 396.62 776.328 396.26 779.558 392.54C779.888 392.16 780.178 391.76 780.438 391.33L787.678 379.44L813.168 340.32L802.608 332.43L780.328 370.29V370.27Z" fill="#F9B194"/>
      <path opacity="0.2" d="M800.428 335.21C800.378 342.14 807.128 348.16 816.658 349.04L838.518 301.86C843.198 291.75 841.718 279.81 834.698 271.2L812.628 298.44C811.938 310.28 806.968 322.55 800.428 335.21Z" fill="#010101"/>
      <path d="M800.428 332.2C800.378 339.13 804.158 343.65 813.688 344.52L835.548 297.34C840.228 287.23 838.748 275.29 831.728 266.68L809.658 293.92C808.968 305.76 806.968 319.54 800.428 332.2Z" fill="#4d07e0"/>
      <path d="M750.96 348.29L791.16 327.74C792.44 327.09 792.67 325.78 791.68 324.82L745.48 280.22C744.49 279.26 742.64 279.01 741.36 279.67L701.16 300.22C699.88 300.87 699.65 302.18 700.64 303.14L746.84 347.74C747.83 348.7 749.68 348.95 750.96 348.29Z" fill="#404D70"/>
      <path d="M750.8 346.21L789.66 326.35C790.38 325.98 790.5 325.25 789.95 324.72L744.26 280.61C743.7 280.07 742.67 279.93 741.96 280.3L703.1 300.16C702.38 300.53 702.26 301.26 702.81 301.79L748.5 345.9C749.06 346.44 750.09 346.57 750.8 346.21Z" fill="white"/>
      <path d="M711.549 300.66C712.319 299.1 713.549 297.77 715.319 297.43C716.019 297.3 716.749 297.33 717.459 297.27C718.929 297.14 719.879 296.21 720.969 295.33C722.709 293.92 724.699 292.72 726.769 291.89C728.509 291.19 730.689 291.58 732.179 290.29C732.849 289.71 733.229 288.86 733.839 288.22C734.579 287.44 735.659 287 736.729 287.04" stroke="#606C8B" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M716.898 304.32C718.048 303.32 719.448 302.65 720.868 302.12C721.578 301.85 722.298 301.6 723.018 301.36C723.828 301.1 724.698 300.96 725.238 300.24C726.938 297.95 730.248 297.14 732.948 297.72" stroke="#606C8B" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M722.109 308.27C724.419 306.13 727.169 305.03 729.989 303.76C731.369 303.14 732.799 302.57 734.039 301.69C735.279 300.81 736.419 299.96 737.839 299.35C740.059 298.4 742.479 297.77 744.349 296.16" stroke="#606C8B" stroke-miterlimit="10" stroke-linecap="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_3954_7016" x1="-11.0972" y1="508.026" x2="182.207" y2="470.062" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F5928F"/>
        <stop offset="1" stop-color="#F8B2B1"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3954_7016" x1="331.72" y1="46.7859" x2="370.764" y2="239.875" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F5928F"/>
        <stop offset="1" stop-color="#F8B2B1"/>
      </linearGradient>
      <clipPath id="clip0_3954_7016">
        <path d="M0 0H1071V552C1071 579.614 1048.61 602 1021 602H0V0Z" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
