const Colors = {
  getOppositeColor(currentColor) {
    if (!currentColor) {
      return '#fff';
    }

    const rgb = this.hexToRgb(currentColor);
    if (this.isLight(rgb.r, rgb.g, rgb.b)) {
      return '#000';
    }
    return '#fff';
  },

  getLightness(r, g, b) {
    return 0.2126 * r + 0.7152 * g + 0.0722 * b
  },

  isDark(r, g, b) {
    return this.getLightness(r, g, b) < 155
  },

  isLight(r, g, b) {
    return this.getLightness(r, g, b) > 155;
  },

  hexToRgb(hex) {
    return {
      r: ('0x' + hex[1] + hex[2]) | 0,
      g: ('0x' + hex[3] + hex[4]) | 0,
      b: ('0x' + hex[5] + hex[6]) | 0,
    };
  },
}

export default Colors;
