<script>
export default {
  name: "tick",
}
</script>

<template>
  <svg data-v-cec44b58="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 21">
    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)"><circle cx="8.5" cy="8.5" r="8"></circle><path d="m5.5 9.5 2 2 5-5"></path></g>
  </svg>
</template>
