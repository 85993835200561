<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full relative">
      <button @click="closePopup" class="absolute text-3xl top-4 right-5 w-10 text-gray-600 hover:text-gray-900">&times;</button>
      <h2 class="text-xl font-semibold mb-4">Story Details</h2>
      <hr>

      <div class="flex items-start mb-4 flex-1 mt-4">
        <div class="text-sm font-medium text-gray-700 w-1/4">Title:</div>
        <div class="text-md font-medium text-gray-700 w-3/4">
           <span v-if="story.label || story.label_color"
                 class="me-2 text-xs border p-1 rounded inline-flex items-center"
                 :style="`background:${story.label_color}; border-color:${story.label_color}; color:${getTextColor(story.label_color)}`">
              {{ story.label ?? ' ' }}
            </span>
          {{ story?.title }}
        </div>
        <a :href="story.url" v-if="story.url"
           title="Story Link"
           target="_blank" class="flex me-2 text-blue-300">
          <URLIcon title="Story Link"/>
        </a>
      </div>

      <div class="flex items-start mb-4 flex-1">
        <div class="text-sm font-medium text-gray-700 w-1/4">Description:</div>
        <div class="text-md font-medium text-gray-700 w-3/4">{{ story?.note }}</div>
      </div>

      <div class="flex items-center mb-4 flex-1">
        <div class="text-sm font-medium text-gray-700 w-1/4">Estimated:</div>
        <div class="text-md font-medium text-blue-700 w-3/4">{{ getCardLabel(story.estimated) }}</div>
      </div>

      <div class="flex items-center mb-4 flex-1">
        <div class="text-sm font-medium text-gray-700 w-1/4">Created At:</div>
        <div class="text-md font-medium text-gray-700 w-3/4">{{ createdAt }}</div>
      </div>

      <div class="flex items-center mb-4 flex-1">
        <div class="text-sm font-medium text-gray-700 w-1/4">Started At:</div>
        <div class="text-md font-medium text-gray-700 w-3/4">{{ startedAt }}</div>
      </div>

      <div class="flex items-center mb-4 flex-1" v-if="story.skipped_at">
        <div class="text-sm font-medium text-gray-700 w-1/4">Skipped At:</div>
        <div class="text-md font-medium text-gray-700 w-3/4">{{duration(story.skipped_at)}}</div>
      </div>

      <div class="flex items-center mb-4 flex-1" v-else>
        <div class="text-sm font-medium text-gray-700 w-1/4">Completed In:</div>
        <div class="text-md font-medium text-gray-700 w-3/4">
          {{duration(story?.completed_at || story?.closed_at)}}
        </div>
      </div>

      <hr/>
      <div class="flex items-start mb-4 flex-1 items-start justify-start mt-6">
        <div class="text-md font-medium text-gray-700 w-full flex flex-col">
          <table class="table w-full">
            <thead>
            <tr class="bg-gray-200 border border-gray-300">
              <td class="p-2">Player</td>
              <td class="p-2 text-center">Vote</td>
              <td class="p-2 text-center">Duration</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(estimate, index) in estimates" :key="`player-${index}`" class="border-b border-gray-200 hover:bg-blue-50">
              <td class="p-2">{{ getPlayerName(estimate?.player_id) }}</td>
              <td class="text-center"><span class="p-2 font-bold text-blue-500">{{ getCardLabel(estimate.score) }}</span></td>
              <td class="text-center"><span class="p-2 text-gray-500">{{ duration(estimate.estimated_at) }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
      <div class="flex gap-4 mt-6">
        <button type="button" @click="closePopup"
                class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 group rounded w-full p-2 disabled:opacity-75 flex justify-center items-center">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from "@/library/cards";
import Colors from "@/library/colors";
import Utils from "@/library/utils";
import RoomStore from '@/store/room';
import URLIcon from '@/assets/url';

export default {
  emits: ['closeDetails'],
  components:{
    URLIcon
  },
  props: {
    story: Object
  },

  computed: {
    estimates() {
      return this.story?.estimates || [];
    },

    createdAt() {
      return Utils.formatDateTime(this.story.created_at);
    },

    startedAt() {
      return Utils.formatDateTime(this.story.started_at);
    },
  },
  methods: {
    getTextColor(color) {
      return Colors.getOppositeColor(color)
    },
    getPlayerName(playerId) {
      return RoomStore.players.find(p => p.user_id === playerId)?.user?.username
    },

    getCardLabel(id) {
      return Cards.find(i => i.id === id)?.value || ''
    },

    duration(endTime) {
      const startTime = this.story?.started_at;

      if (!startTime || !endTime) {
        return '-';
      }

      const elapsedMilliseconds = new Date(startTime) - new Date(endTime);
      return Utils.getFormattedTime(Math.floor(Math.abs(elapsedMilliseconds) / 1000));
    },

    closePopup() {
      this.$emit('closeDetails')
    },
  }
};
</script>
