<script>
import URLIcon from '@/assets/url';
import CardPokers from "@/components/CardPokers.vue";
import RoomActions from "@/components/RoomActions.vue";
import RoomChart from "@/components/RoomChart.vue";
import RoomPlayers from "@/components/RoomPlayers.vue";
import Stories from "@/components/StoriesList";
import Base64 from "@/library/base64";
import Colors from "@/library/colors";
import Storage from "@/library/storage";
import AppStore from '@/store/app';
import RoomStore from '@/store/room';
import {toast} from "vue3-toastify";

export default {
  name: "Room",
  components: {
    RoomPlayers,
    RoomActions,
    Stories,
    CardPokers,
    URLIcon,
    RoomChart
  },

  data() {
    return {
      room: null,
      showStoryForm: false,
      channel: null,
      roomId: null,
      isViewMode: false
    };
  },


  mounted() {
    const param = Base64.decrypt(this.$route.params.id);
    this.roomId = param.split('?v=')[0];
    this.isViewMode = param.split('?v=')[1] === 'view';
    console.log(this.isViewMode);
    this.$nextTick(async () => {
      await this.fetch();
      await setTimeout(() => this.initPusher(), 200)
    })
  },

  beforeUnmount() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  },

  computed: {
    // roomId() {
    //
    //   return this.$route.params.id;
    // },

    RoomName() {
      return RoomStore.room?.title || '';
    },

    playerId() {
      return Storage.get('USER_SESSION')?.user?.id;
    },

    isOwner() {
      return !!RoomStore?.isIamOwner;
    },
    story() {
      return RoomStore?.activeStory || null;
    },

    storyCompleted() {
      return RoomStore?.isStoryCompleted;
    },
    isLoading() {
      return AppStore.isLoading;
    }
  },

  created() {
    AppStore.loading = true;
  },

  methods: {
    fetch() {
      return RoomStore.fetch(this.roomId)
          .then(async (room) => {
            const currentPlayer = room?.players?.find(i => i.user_id === this.playerId);
            if (currentPlayer) {
              return
            }
            if(!this.isViewMode){
              await RoomStore.join(room.id, this.playerId);
            }

          })
          .catch((error) => {
            toast.error(error?.message || 'Something went wrong!');
            this.$router.push('/');
          })
    },
    initPusher() {
      this.channel = this.$pusher.subscribe(`Room_Channel_${ this.roomId }`);
      this.channel.bind('RoomUpdated', ({roomId, resource, action}) => {
        if (!roomId || (resource === 'room' && action === 'delete')) {
          return;
        }

        RoomStore.fetchRoom(roomId).then((room) => {
          return;
          if (resource === 'room') {
            RoomStore.room = room;
          } else if (resource === 'estimates' && RoomStore.room?.['active_story']) {
            RoomStore.room['active_story'][resource] = room[resource];
          } else {
            RoomStore.room[resource] = room[resource];
          }
        })

      });
      AppStore.loading = false;
    },
    getTextColor(color){
      return Colors.getOppositeColor(color)
    }
  }
};
</script>

<template>
  <div class="flex flex-col gap-2 mb-2 w-full max-w-7xl m-auto max-sm:w-full min-h-screen" v-if="!isLoading">
    <div class="flex w-full mr-4 mb-2">
      <div class="flex w-full bg-white rounded shadow mr-4 max-sm:m-auto">
        <div class="relative flex w-full p-4 border-l-4 border-l-blue-500 rounded">
          <span class="text-2xs absolute top-0 left-4 bg-blue-500 px-2 rounded-b text-white" >Room Name</span>
          <b class="capitalize">{{ RoomName }}</b>
        </div>
      </div>
    </div>
    <div class="flex w-full max-sm:flex-col items-start">
      <div class="flex flex-col w-2/3 max-sm:w-full">
        <div v-if="!!story" class="flex w-full bg-white shadow rounded">
          <div class="relative flex w-full border-l-4 border-l-green-600 rounded flex justify-between items-center p-4 ">
            <span class="text-2xs absolute top-0 left-4 bg-green-600 px-2 rounded-b text-white" >Story Name</span>

            <div class="flex items-center">
              <span v-if="story.label || story.label_color" class="text-xs border p-1 rounded me-2"
                    :style="`background:${story?.label_color}; border-color:${story?.label_color}; color:${getTextColor(story.label_color)}`">
                {{story?.label ?? ' ' }}
              </span>
              <b class="me-1 capitalize">{{ story.title }}</b>
            </div>

          </div>

          <a :href="story.url" v-if="story.url" title="Story Link" target="_blank" class="flex me-2 text-blue-300 flex-col justify-center items-center px-1">
            <URLIcon/>
          </a>
        </div>
        <div class="flex flex-col flex-1">
          <room-chart v-if="storyCompleted"/>
          <card-pokers v-else/>
          <div class="w-full flex flex-col max-sm:hidden flex-col">
             <stories @fetchStories="fetch"/>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/3 max-sm:w-full">
        <room-actions :is-owner="isOwner"/>
        <room-players :is-owner="isOwner"/>
      </div>
      <div class="w-full hidden max-sm:flex flex-col mt-4">
        <stories @fetchStories="fetch"/>
      </div>
    </div>
  </div>
</template>
