<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full">
      <button @click="closePopup" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900">&times;</button>
      <h2 class="text-xl font-semibold mb-4">Enter Story Details</h2>
      <form @submit.prevent="handleSave">
        <div class="mb-4">
          <label for="storyText" class="block text-sm font-medium text-gray-700">Story Title:</label>
          <textarea id="storyText" v-model="storyText" rows="4"
                    placeholder="Put your stories text here. Each line contains new story."
                    class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required>
          </textarea>
        </div>
        <div class="grid grid-cols-2 grid-rows-2 gap-2">
          <button type="button" @click="saveAndClose"
                  class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full">Save and Close
          </button>
          <button type="button" @click="saveAndNew"
                  class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full">Save and New
          </button>
          <button type="button" @click="$emit('openImportStories')"
                  class="border border-blue-500 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full">
            Import
          </button>
          <button type="button" @click="closePopup"
                  class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-opacity-5 group rounded-md w-full p-2 disabled:opacity-75 flex justify-center items-center">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FBApp from "@/library/firebase";
import RoomStore from '@/store/room';
import {toast} from "vue3-toastify";

const {logEvents} = FBApp;
export default {
  emits: ['fetchStories', 'openImportStories'],
  data() {
    return {
      roomId: this.$route.params.id,
      isVisible: false,
      storyText: ''
    };
  },

  computed: {
    stories() {
      return this.storyText.split('\n')
          .filter(text => !!text.trim())
          .map(text => text.substring(0, 99));
    }
  },

  methods: {
    closePopup() {
      RoomStore.toggleStoryForm(false);
    },

    handleSave(hasClose) {
      RoomStore.storeStories([...this.stories])
          .then(data => {
            toast.success(data?.message || 'Saved successfully');
            this.$emit('fetchStories');
            if (hasClose) {
              this.closePopup();
              return;
            }
            logEvents('Success Saved Stories')
            this.storyText = '';
          })
          .catch(error => {
            logEvents('Error Saved Stories')
            toast.error(error?.message || 'Something went wrong!')
          })
    },

    saveAndClose() {
      this.handleSave(true);
    },

    saveAndNew() {
      this.handleSave();
    }
  }
};
</script>
