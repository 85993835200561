import FBApp from "@/library/firebase";
import Pusher from '@/library/pusher';
import {createApp} from 'vue';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import App from './App.vue'
import Button from '@/components/Button';
import router from './router';
import './styles/index.css';
import VueApexCharts from "vue3-apexcharts";
import JsonExcel from "vue-json-excel3";
const {Analytics} = FBApp;
Analytics();
const PlanItApp = createApp(App);
PlanItApp.use(Pusher, {
  apiKey: process.env["VUE_APP_PUSHER_APP_KEY"],
  cluster: process.env["VUE_APP_PUSHER_CLUSTER"],
});

PlanItApp.use(Vue3Toastify, {
  autoClose: 3000,
  theme: 'colored',
});

PlanItApp.component("downloadExcel", JsonExcel);

PlanItApp.use(router);
PlanItApp.component('Button',Button);
PlanItApp.component('ApexChart',VueApexCharts);
PlanItApp.mount('#app');
