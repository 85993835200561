import axios from "axios";
import Storage from "@/library/storage";
const isProd = window.location.hostname !== 'localhost';
// const baseURL = isProd ? process?.env?.VUE_APP_PROD_BASE_URL : process?.env?.VUE_APP_BASE_URL;
const baseURL = isProd ? 'https://api.planitesy.com/api/' : 'http://localhost:8081/api/';

const request = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
    }
});

request.interceptors.request.use(function (config) {
    if (!!Storage.get('USER_SESSION')) {
        const session = Storage.get('USER_SESSION');
        config.headers['Authorization'] = `${ session?.token_type } ${ session?.access_token }`;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
    return response.data || response;
}, function (error) {
    return Promise.reject(error?.response?.data || error?.response || error);
});

export default request
