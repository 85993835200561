<template>
  <MenuPopup>
    <template #menuButton="props">
      <button @click="props.toggleMenu" class="focus:outline-none">
        <MenuIcon class="text-blue-700"/>
      </button>
    </template>

    <template #menuBody="props">
      <router-link to="/account" @click="props.toggleMenu"
                   class="flex flex-col px-4 py-2 hover:bg-gray-100 hover:text-blue-500 overflow-hidden border-b border-gray-200">
        <div class="font-bold text-lg truncate w-32">{{ username }}</div>
        <div class="flex text-xs text-gray-600">User Profile</div>
      </router-link>
      <router-link to="/" @click="props.toggleMenu"
                   class="block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-blue-500 border-b border-gray-200">Rooms
      </router-link>
      <router-link to="#" @click.prevent="logout($event, props.toggleMenu)"
                   class="block px-4 py-2 text-red-600 hover:bg-gray-100 hover:text-blue-500">Logout
      </router-link>
    </template>
  </MenuPopup>
</template>

<script>
import MenuIcon from "@/assets/menu";
import MenuPopup from "@/components/MenuPopup";
import axios from "@/library/request";
import Storage from "@/library/storage";
import AppStore from "@/store/app";

export default {
  components: {
    MenuIcon,
    MenuPopup
  },
  computed: {
    username() {
      const user = AppStore.session?.user;
      return user?.username?.split(' ')?.[0] || '';
    }
  },
  methods: {
    logout(event, closeMenu) {
      event.preventDefault();
      closeMenu();
      axios.post('/auth/logout')
          .finally(() => {
            AppStore.session = null;
            Storage.remove('USER_SESSION');
            this.$router.replace({path: '/login'});
          })
    }
  },
};
</script>
