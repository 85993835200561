export default [
  {id: 0, value: 0},
  {id: 1, value: 0.5},
  {id: 2, value: 1},
  {id: 3, value: 2},
  {id: 4, value: 3},
  {id: 5, value: 5},
  {id: 6, value: 8},
  {id: 7, value: 13},
  {id: 8, value: 20},
  {id: 9, value: 40},
  {id: 10, value: 100},
  {id: 100, value: '?'},
  {id: 101, value: 'Coffee'},
];
