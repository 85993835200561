const Utils = {
  getFormattedTime(elapsedSeconds) {
    const hours = Math.floor(elapsedSeconds / 3600);
    const minutes = Math.floor((elapsedSeconds % 3600) / 60);
    const seconds = elapsedSeconds % 60;

    return `${ this.pad(hours) }:${ this.pad(minutes) }:${ this.pad(seconds) }`;
  },

  pad(number) {
    return number.toString().padStart(2, '0');
  },

  formatDateTime(dateString) {
    const date = new Date(dateString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return date.toLocaleDateString('en-US', options);
  }
}

export default Utils;
