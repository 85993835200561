import axios from "@/library/request";
import Timer from "@/library/timer";
import AppStore from '@/store/app';
import {reactive, ref} from 'vue';

const RoomStore = reactive({
  room: null,
  storyFormStatus: false,
  timer: null,
  stories: ref([]),
  completedStories: ref([]),
  allStories: ref([]),

  fetch(roomId) {
    return new Promise((resolve, reject) => {
      this.fetchRoom(roomId)
        .then(async (room) => {
          if (this.activeStory?.started_at) {
            await this.buildTimer();
          }
          return resolve(room);
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },

  fetchRoom(roomId) {
    return new Promise((resolve, reject) => {
      axios.get(`/room/${ roomId }`)
        .then(({room}) => {
          this.stories = [];
          this.room = room;

          this.stories = this.room?.stories?.filter(i => !i.closed_at) || [];
          this.completedStories = this.room?.stories?.filter(i => !!i.completed_at) || [];
          this.allStories = this.room?.stories || [];

          this.flipCardsIfNeeded()

          resolve(room);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  flipCardsIfNeeded() {
    if(!this.isStoryReadyToVote) {
      return;
    }

    console.log('Start checking is need to flip')
    const playersEst = {};
    const areNotEstPlayers = [];
    // set Players est.
    this.scores?.forEach(est => {
      playersEst[est.player_id] = est;
    })
    // check is all players are est.ed
    this.players?.forEach(player=>{
      if(!playersEst[player.user_id]){
        areNotEstPlayers.push(player.user_id)
      }
    })

    // skipping from the flipping cards
    if(areNotEstPlayers.length){
      return ;
    }

    return this.setStoryCompleted();
  },

  join(roomId, userId) {
    return axios.post(`room/${ roomId }/join/${ userId }`).catch((error) => {
      console.log('Error', error)
    })
  },

  selectStory(storyId) {
    axios.put(`story/${ storyId }/active`)
      .then(({story}) => {
        this.room.active_story = story;
        this.buildTimer();
      })
      .catch((error) => {
        console.log('Error', error)
      })
  },

  resetTimer() {
    const storyId = this.activeStory?.id;
    axios.put(`story/${ storyId }/resetTimer`).then(({story}) => {
      this.room.active_story = story;
      this.timer.reset();
      this.buildTimer();
    }).catch((error) => {
      console.log('Error', error)
    })
  },

  setStoryCompleted() {
    return axios.put(`story/${ this.activeStory.id }/completed`)
      .then(({story}) => {
        this.room.active_story = story;
        if (this.timer) {
          this.timer.reset();
        }
      }).catch((error) => {
        console.log('Error', error)
      })
  },

  setStorySkipped() {
    return axios.put(`story/${ this.activeStory.id }/skipped`)
      .then(async () => {
        await this.fetch(this.room.id);

        if (this.timer) {
          this.timer.reset();
        }
      }).catch((error) => {
        console.log('Error', error)
      })
  },

  setStoryClosed(estimated) {
    return axios.put(`story/${ this.activeStory.id }/closing`, {estimated})
      .then(async () => {
        await this.fetch(this.room.id);
        if (this?.stories[0]?.id) {
          this.selectStory(this?.stories[0]?.id);
        }
      }).catch((error) => {
        console.log('Error', error)
      })
  },

  clearVotes() {
    return axios.put(`story/${ this.activeStory.id }/clearVotes`)
      .then(async () => {
        await this.fetch(this.room.id);
        if (this.timer) {
          this.resetTimer()
        }
      }).catch((error) => {
        console.log('Error', error)
      })
  },

  setStoriesOrder(stories) {
    return axios.put(`room/${ this.room.id }/stories_order`, {stories})
      .then(async (data) => {
        console.log(data);
      }).catch((error) => {
        console.log('Error', error)
      })
  },

  startingRoom() {
    return axios.post(`room/${ this.room.id }/starting`).catch((error) => {
      console.log('Error', error)
    })
  },

  toggleStoryForm(isOpen) {
    this.storyFormStatus = isOpen;
  },

  buildTimer() {
    // prevent starting timer if completed at
    if(this.activeStory?.completed_at){
      this.timer?.reset();
      return;
    }
    this.timer = new Timer(this.activeStory?.started_at);
    this.timer.start();
  },

  storeStories(titles){
    return axios.post('story/store', {
      titles,
      roomId: this.room.id,
    })
  },

  get activeStory() {
    return this.room?.active_story;
  },

  get players() {
    return this.room?.players || []
  },

  get scores() {
    // return [
    //   {score:1},
    //   {score:1},
    //   {score:1},
    //   {score:1},
    //   {score:1},
    //   {score:2},
    //   {score:3},
    //   {score:4},
    //   {score:2},
    //   {score:2},
    //   {score:3},
    //   {score:2},
    //   {score:4},
    //   {score:3},
    //   {score:1},
    //   {score:200},
    //   {score:200},
    //   {score:200},
    //   {score:1},
    //   {score:1},
    //   {score:1},
    //   {score:100},
    //   {score:100},
    //   {score:100},
    // ];
    return this.activeStory?.estimates;
  },

  get isStarted() {
    return !!this.room?.started_at;
  },

  get isCompleted() {
    return !!this.room?.active_story?.completed_at;
  },

  get isStoryCompleted() {
    return !!this.room?.active_story?.completed_at;
  },

  get isStoryClosed() {
    return !!this.room?.active_story?.closed_at;
  },

  get isStoryReadyToVote() {
    return !!this.isStarted &&
      !!this.timer &&
      !!this.activeStory &&
      !!this.players?.length &&
      !!this.scores?.length &&
      !this.isStoryCompleted
  },

  get isIamOwner() {
    return this.room?.['user_id'] === AppStore.userId;
  }

});

export default RoomStore;
