<script>
import DragIcon from "@/assets/drag";
import RoomStore from "@/store/room";
import Colors from '@/library/colors';
import TrashIcon from "@/assets/trash";

export default {
  name: "ActiveStories",
  emits: ['click', 'deleteStory'],
  props: {
    showDelete: Boolean,
  },
  components: {
    DragIcon,
    TrashIcon
  },
  data: () => ({
    oldIndex: null,
    newIndex: null,
    dragOver: null,
    dragInto: null,
  }),

  computed: {
    stories() {
      return RoomStore?.stories;
    },

    isOwner() {
      return !!RoomStore?.isIamOwner;
    },
  },

  methods: {
    handleOnDragStar(oldIndex) {
      this.oldIndex = oldIndex;
    },
    handleOnDragOver(newIndex) {
      this.newIndex = newIndex;
    },
    handleOnDragEnd() {
      let stories = this.stories;
      this.dragOver = null;
      const item = stories.splice(this.oldIndex, 1)[0];
      stories.splice(this.newIndex, 0, item);
      stories = stories.map((item, order) => {
        return {
          ...item,
          order
        }
      })
      RoomStore.stories = this.stories;
      this.$nextTick(() => {
        RoomStore.setStoriesOrder(this.stories);
        this.oldIndex = this.newIndex = null
        this.$forceUpdate();
      })
    },

    getTextColor(color){
      return Colors.getOppositeColor(color)
    }
  },
}
</script>

<template>
  <ul>
    <li v-for="(story, index) in stories" :key="`story-${story.id}`"
        class='cursor-pointer px-4 hover:bg-blue-50 border-2'
        :class="`
           ${index % 2 === 0 ?'bg-gray-100':''}
           ${index === newIndex && index !==  oldIndex?'border-dashed border-green-600 opacity-30':'border-transparent' }
           ${index ===  oldIndex?'shadow-lg border-gray-300 bg-white opacity-1':''}
           `"
        :draggable="isOwner"
        :dataIndex="index"
        @click="$emit('click', story)"
        :ondragstart="()=>handleOnDragStar(index)"
        :ondragend="()=>handleOnDragEnd()"
        :ondragover="()=>handleOnDragOver(index)">
      <div class="px-4 py-4 ps-0 flex justify-between group">
        <div class="flex items-center w-full" @click="editStory = story">
          <div class="w-1/10">
            <DragIcon class="text-gray-500" v-if="isOwner"/>
          </div>
          <div class="inline-flex  w-6/10 items-center justify-start">
            <div v-if="story.label || story.label_color" class="me-2 text-xs border p-1 rounded"
                 :style="`background:${story.label_color}; border-color:${story.label_color}; color:${getTextColor(story.label_color)}`">
              {{ story.label ?? ' ' }}
            </div>
            {{ story.title }}
          </div>
        </div>
        <div class="w-4/10 flex justify-center items-center">
          <button v-if="showDelete" @click="(e)=>$emit('deleteStory', e, story.id)" title="Remove Story"
                  class="text-gray-400 text-xs min-w-3">
            <TrashIcon class="flex opacity-0 group-hover:opacity-100 text-red-600 w-6" />
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>
