<script>
import AddIcons from "@/assets/add";
import EmptyStoriesIcon from "@/assets/empty-stories";
import RetryIcon from "@/assets/retry";
import SettingIcon from "@/assets/setting";
import TrashIcon from "@/assets/trash";
import ImportStories from "@/components/ImportStories";
import MenuPopup from "@/components/MenuPopup";
import ActiveStories from "@/components/RoomStories/ActiveStories";
import StoryDetails from "@/components/StoryDetails";
import StoryForm from "@/components/StoryForm";
import StoryUpdateForm from "@/components/StoryUpdateForm";
import Cards from "@/library/cards";
import Colors from "@/library/colors";
import FBApp from "@/library/firebase";
import axios from "@/library/request";
import Utils from "@/library/utils";
import RoomStore from '@/store/room';
import {toast} from "vue3-toastify";

const {logEvents} = FBApp;

export default {
  name: "StoriesList",
  emits: ['fetchStories'],

  components: {
    StoryForm,
    StoryUpdateForm,
    TrashIcon,
    RetryIcon,
    ActiveStories,
    StoryDetails,
    AddIcons,
    SettingIcon,
    ImportStories,
    EmptyStoriesIcon,
    MenuPopup
  },

  data: () => ({
    activeTab: 1,
    editStory: null,
    oldIndex: null,
    newIndex: null,
    storiesList: [],
    storyDetails: null,
    settingModal: false,
    importStoriesPopup: false
  }),
  computed: {
    stories() {
      if (this.activeTab === 2) {
        return RoomStore.completedStories
      }

      if (this.activeTab === 3) {
        return RoomStore.allStories
      }

      return RoomStore.stories

    },

    allStories() {
      return RoomStore.allStories;
    },

    isStoryForm() {
      return !!RoomStore?.storyFormStatus;
    },

    isOwner() {
      return !!RoomStore?.isIamOwner;
    },
    totalStores() {
      return RoomStore?.stories?.length;
    },
    totalClosedStores() {
      return RoomStore?.completedStories?.length;
    },
    totalAllStores() {
      return RoomStore?.allStories?.length;
    },
    excelFields() {
      return {
        'Story Title': 'title',
        'Est': 'estimated',
        'Time': 'estimated_at',
      }
    },
    excelData() {
      return [...this.allStories].map(story => {
        return {
          title: story.title,
          estimated: this.getCardLabel(story?.estimated),
          estimated_at: this.storyTimeTaking(story),
        }
      })
    },
    excelFileName() {
      return `${ RoomStore?.room?.title?.replace(/[^a-zA-Z0-9_]/g, '_') }.xlsx`
    },
    excelMeta() {
      return [
        {
          key: "charset",
          value: "utf-8",
        },
      ]
    },
  },

  methods: {
    openImportStories() {
      this.isStoryForm = false;
      this.importStoriesPopup = true;
      logEvents('Open Story Import Form');
    },
    showStoryForm() {
      RoomStore.toggleStoryForm(true);
      logEvents('Open Story Form');
    },
    toggleStoryImport(isActive) {
      if (!isActive) {
        return setTimeout(() => {
          this.settingModal = isActive;
        }, 500)
      }
      this.settingModal = isActive;
    },
    deleteStory(e, id) {
      e.preventDefault();
      e.stopPropagation();
      const isConfirm = confirm('Are you sure to delete story?');
      if (!isConfirm) {
        return;
      }
      axios.delete(`/story/${ id }`).then((data) => {
        this.$emit("fetchStories");
        logEvents('Delete Story');
        toast.success(data?.message || 'Story Deleted Successfully');
      }).catch((error) => {
        logEvents('Error Delete Story');
        toast.error(error?.message || 'Something went wrong!');
      })
    },
    retryStory(storyId) {
      const isConfirm = confirm('Are you sure to restart story?');
      if (!isConfirm) {
        return;
      }
      axios.put(`story/${ storyId }/retry`).then((data) => {
        toast.success(data?.message || 'Story Updated Successfully');
        logEvents('Retry Story');
      }).catch((error) => {
        logEvents('Error Retry Story');
        toast.error(error?.message || 'Something went wrong!');
      })
    },

    handleOnCloseUpdateForm() {
      this.editStory = null;
      this.$emit('fetchStories');
    },

    handleOnSaveStories() {
      this.importStoriesPopup = false;
      this.$emit('fetchStories');
    },

    storyTimeTaking(story) {
      const startTime = story?.started_at;
      const endTime = story?.completed_at || story?.closed_at;

      if (!startTime || !endTime) {
        return '-';
      }

      const elapsedMilliseconds = new Date(startTime) - new Date(endTime);
      return Utils.getFormattedTime(Math.floor(Math.abs(elapsedMilliseconds) / 1000));
    },

    isClosed(story) {
      return !!story?.started_at && (!!story?.skipped_at || !!story?.completed_at || !!story?.closed_at);
    },

    getCardLabel(id) {
      return Cards.find(i => i.id === id)?.value || ''
    },
    getTextColor(color) {
      return Colors.getOppositeColor(color)
    },

    handleOnExport(closeMenu) {
      logEvents('Export File Successfully');
      closeMenu();
    },

    handleOnImport(closeMenu) {
      logEvents('Open Import File Modal');
      this.importStoriesPopup = true;
      closeMenu();
    }

  },
}
</script>

<template>
  <div class="flex flex-col w-full border border-gray-200 rounded bg-white max-sm:mb-2"
       @focusout="toggleStoryImport(false)" @blurout="toggleStoryImport(false)">
    <div class="flex justify-between">
      <div class="flex gap-2">
        <button
            class="relative font-semibold text-md p-4 border-b-2 border-white hover:opacity-100 hover:text-blue-500"
            :class="`${activeTab === 1?'border-blue-300 opacity-100 text-blue-500':'opacity-60'}`"
            @click="activeTab = 1"

        >
          <span
              class="absolute top-2 end-0 w-5 h-5 text-2xs text-white rounded-full flex justify-center items-center"
              v-if="totalStores"
              :class="`${activeTab !== 1?'bg-gray-400':'bg-red-600'}`"
          >
            {{ totalStores }}
          </span>
          <div>Active <span class="max-sm:hidden">Stories</span></div>
        </button>

        <button
            class="relative font-semibold text-md p-4 border-b-2 border-white hover:opacity-100 hover:text-blue-500"
            :class="`${activeTab === 2?'border-blue-300 opacity-100 text-blue-500':'opacity-60'}`"
            @click="activeTab = 2"
        >
          <span
              class="absolute top-2 end-0 w-5 h-5 text-2xs text-white rounded-full flex justify-center items-center"
              v-if="totalClosedStores"
              :class="`${activeTab !== 2?'bg-gray-400':'bg-red-600'}`"
          >
            {{ totalClosedStores }}
          </span>
          <div>Completed <span class="max-sm:hidden">Stories</span></div>
        </button>

        <button
            class="relative font-semibold text-md p-4 border-b-2 border-white hover:opacity-100 hover:text-blue-500"
            :class="`${activeTab === 3?'border-blue-300 opacity-100 text-blue-500':'opacity-60'}`"
            @click="activeTab = 3"
        >
          <span
              class="absolute top-2 end-0 w-5 h-5 text-2xs text-white rounded-full flex justify-center items-center"
              :class="`${activeTab !== 3? 'bg-gray-400':'bg-red-600'}`"
              v-if="totalAllStores"
          >
            {{ totalAllStores }}
          </span>
          <div><span class="max-sm:hidden">All</span> Stories</div>
        </button>
      </div>

      <div class="flex gap-2 relative items-center" v-if="isOwner">
        <button @click="showStoryForm"
                class="flex items-center justify-center text-blue-500 text-sm px-4 py-2 max-sm:px-1">
          <AddIcons class="text-blue-500"/>
          <span class="max-sm:hidden">Add Story</span>
        </button>
        <MenuPopup>
          <template #menuButton="props">
            <button @click.prevent="()=>props.toggleMenu()"
                    class="flex items-center justify-center text-blue-500 text-sm px-4 py-2 max-sm:px-1">
              <SettingIcon class="text-blue-500"/>
              <span class="max-sm:hidden">Setting</span>
            </button>
          </template>
          <template #menuBody="props">
            <div
                class="flex flex-col px-4 py-2 hover:bg-gray-100 hover:text-blue-500 cursor-pointer border-b border-ray-200"
                @click="()=>handleOnImport(props.toggleMenu)"
            >
              Import
            </div>

            <download-excel
                v-if="!!totalAllStores"
                class="block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-blue-500 cursor-pointer"
                :data="excelData"
                :fields="excelFields"
                type="xlsx"
                :metadata="excelMeta"
                @click="()=>handleOnExport(props.toggleMenu)"
                :name="excelFileName">
              Export
            </download-excel>
          </template>
        </MenuPopup>

      </div>
    </div>
    <ActiveStories v-if="activeTab ===1" @click="(story)=>editStory = story" :showDelete="isOwner"
                   @deleteStory="(e, id)=>deleteStory(e, id)"/>
    <table v-else class="table">
      <thead class="bg-gray-200">
      <tr>
        <td class="p-4 font-bold">Title</td>
        <td class="p-4 font-bold">Est.</td>
        <td class="p-4 font-bold">Time</td>
        <td class="p-4 font-bold"></td>
        <td class="p-4 font-bold text-end"></td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(story, index) in stories" :key="`story-${story.id}`"
          :class="{'bg-gray-100':index%2===0}"
          class='cursor-pointer px-4 hover:bg-blue-50'
      >
        <td class="px-4 py-4">
          <div class="flex items-center w-full" @click="()=>editStory = story">
            <span>{{ story.title }}</span>
            <span v-if="story.label || story.label_color"
                  class="mx-2 text-xs border p-1 rounded"
                  :style="`background:${story.label_color}; border-color:${story.label_color}; color:${getTextColor(story.label_color)}`">
              {{ story.label ?? ' ' }}
            </span>
          </div>
        </td>
        <td class="px-4">
          <div class="text-gray-400 hover:text-blue-900" @click="storyDetails = story">
            {{ !!story?.skipped_at ? 'Skipped' : getCardLabel(story?.estimated) || '-' }}
          </div>
        </td>
        <td class="px-4">
          <div class="text-gray-400 hover:text-blue-500 hover:underline" @click="storyDetails = story">
            {{ storyTimeTaking(story) }}
          </div>
        </td>
        <td class="px-4 text-center">
          <button v-if="isOwner && isClosed(story)" @click="retryStory(story.id)" title="Retry Estimation"
                  class="text-gray-400 text-xs w-3 px-4 py-2">
            <RetryIcon/>
          </button>
        </td>
        <td class="px-4 text-end">
          <button v-if="isOwner" @click="(e)=>deleteStory(e,story.id)" title="Remove Story"
                  class="text-red-500 text-xs px-4 py-2">
            <TrashIcon class=" w-6"/>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <EmptyStoriesIcon v-if="!totalAllStores" class="max-sm:w-full"/>

    <StoryForm v-if="isStoryForm"
               @fetchStories='$emit("fetchStories")'
               @openImportStories="openImportStories"
    />
    <StoryUpdateForm v-if="editStory"
                     :story="editStory"
                     @close='handleOnCloseUpdateForm'
    />
    <StoryDetails v-if="storyDetails"
                  :story="storyDetails"
                  @closeDetails='storyDetails = null'
    />
    <ImportStories v-if="!!importStoriesPopup"
                   @fetchStories='$emit("fetchStories")'
                   @closeImport='importStoriesPopup = false'/>
  </div>
</template>
