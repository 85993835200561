<template>
  <div id="app" class="min-h-screen bg-gray-200 ">
    <header class="bg-white shadow">
      <nav class="container mx-auto p-4 flex justify-between items-center w-full max-w-7xl">
        <router-link to="/" class="text-xl font-bold text-blue-700 flex items-center">
          <img class="w-6 me-2 h-6" :src="appLogo" alt="App Logo"/>
          <span class="font-simbold text-blue-600 text-2xl">{{ AppName }}</span>
        </router-link>
        <div v-if="isLoggedIn">
          <AppMenu />
        </div>
      </nav>
    </header>
    <main class="container mx-auto p-4 max-w-[1100px] max-sm:px-2 min-h-[89vh]">
      <router-view></router-view>
      <app-loader/>
    </main>
    <div class="flex bg-white w-full p-4 shadow mt-4">
      <div class="container mx-auto flex justify-between items-center w-full max-w-7xl text-2xs text-blue-800 font-bold italic">
        Powered by foodics
      </div>
    </div>
  </div>
</template>

<script>
import AppLoader from "@/components/AppLoader";
import AppMenu from "@/components/Menu";
import Storage from "@/library/storage";
import AppStore from "@/store/app";
import axios from "@/library/request";

export default {
  name: 'App',
  components: {
    AppLoader,
    AppMenu
  },
  data: () => ({
    // appLogo: require('@/assets/logo.png'),
    appLogo: require('@/assets/logo.jpg'),
  }),
  computed: {
    AppName() {
      return process?.env?.VUE_APP_NAME
    },
    isLoggedIn() {
      return !!AppStore?.session;
    }
  },
  beforeMount() {
    this.fetchMe();
    this.setUserSession();
  },
  methods: {
    setUserSession() {
      if (Storage.get('USER_SESSION')) {
        AppStore.session = Storage.get('USER_SESSION');
      }
    },
    fetchMe(){
      // fetch user's information'
      if(!Storage.get('USER_SESSION')){
        return
      }

      const session = Storage.get('USER_SESSION');
      axios.post('auth/me').then((user)=>{
        AppStore.session = {
          ...session,
          user: {
            ...user
          }
        }
        console.log('AppStore.session',AppStore.session)
      })
    }

  }
}
</script>

