<!-- src/components/Header.vue -->
<template>
  <header class="bg-blue-500 p-4 text-white">
    <div class="container mx-auto flex justify-between items-center">
      <h1 class="text-xl font-bold">PlanItPoker</h1>
      <nav>
        <ul class="flex space-x-4">
          <li><router-link to="/" @click="logout" class="hover:underline">Log Out</router-link></li>
          <li><router-link to="/room" class="hover:underline">Room</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import axios from "@/library/request";
import Storage from "@/library/storage";
import {toast} from "vue3-toastify";

export default {
  name: "Header",
  methods: {
    logout(event){
      event.preventDefault();
      axios.post('/auth/logout').then(() => {
        Storage.remove('USER_SESSION');
        this.$router.replace({path: '/login'});
      }).catch((error) => {
        toast.error(error?.message || 'Something went wrong!');
      })
    }
  }
};
</script>
